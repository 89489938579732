import { GROUP_PERMISSIONS } from "./default_perms"

export const userStatusOptions = [
    { value: true, label: 'Hoạt động' },
    { value: false, label: 'Đang khoá' },
]

export const packageTypeOptions = [
    { value: 'EXPIRE_DAY', label: 'Hết hạn theo số ngày' },
    { value: 'EXPIRE_END_MONTH', label: 'Hết hạn vào ngày cuối tháng' },
    { value: 'EXPIRE_MONTH', label: 'Hết hạn vào ngày đó của tháng sau' },
]

export const partnerPackageTypeOptions = [
    { value: '3G', label: 'Tài khoản gốc' },
    { value: 'ADDON', label: 'Data Sponsor' },
    { value: 'CONNECTION', label: 'Đấu mới' },
    { value: 'EXTENSION', label: 'Gia hạn' },
]

export const packageStatusOptions = [
    { value: '1', label: 'Hoạt động' },
    { value: '2', label: 'Đang khoá' },
]

export const simPassportStatusOptions = [
    { value: 'COMPLETED', label: 'Hoàn thành' },
    { value: 'CANCELLED', label: 'Đã huỷ bỏ' },
    { value: 'PENDING', label: 'Chờ xác nhận' },
]

export const orderStatusOptions = [
    { value: 'COMPLETED', label: 'Hoàn thành' },
    { value: 'CANCELLED', label: 'Đã huỷ bỏ' },
    { value: 'CREATED', label: 'Đã tạo' },
    { value: 'PROCESSING', label: 'Đang xử lý' },
    { value: 'PENDING', label: 'Đang nạp excel' },
]

export const mbhStatusOptions = [
    { value: 'SUCCESS', label: 'Thành công' },
    { value: 'FAIL', label: 'Thất bại' },
    { value: 'ERROR', label: 'Lỗi' },
    { value: 'CREATED', label: 'Đã tạo' },
    { value: 'PROCESSING', label: 'Đang xử lý' },
]

export const transactionConnectionTypeOptions = [
    { value: 'CONNECTION', label: 'Đấu mới' },
    { value: 'EXTENSION', label: 'Gia hạn' },
]

export const priceTopupOptions = [
    { value: 5000, label: '5,000 đ' },
    { value: 100000, label: '100,000 đ' },
    { value: 480000, label: '480,000 đ' },
    { value: 500000, label: '500,000 đ' },
    { value: 750000, label: '750,000 đ' },
    { value: 960000, label: '960,000 đ' },
    { value: 1500000, label: '1,500,000 đ' },
    { value: 1800000, label: '1,800,000 đ' },
    { value: 2400000, label: '2,400,000 đ' },
]

export const packageTkgOptions = [
    { value: 'M2M200T', label: 'M2M200T - 3G - 200,000 đ/30 ngày' },
    { value: 'M2M70T', label: 'M2M70T - 3G - 70,000 đ/30 ngày' },
    { value: 'M2M300T', label: 'M2M300T - 3G - 300,000 đ/30 ngày' },
    { value: 'M2M50T', label: 'M2M50T - 3G - 50,000 đ/30 ngày' },
    { value: 'M2M250T', label: 'M2M250T - 3G - 250,000 đ/30 ngày' },
    { value: '6M128', label: '6M128 - 3G - 30,000 đ/180 ngày' },
    { value: 'M2M120T', label: 'M2M120T - 3G - 120,000 đ/30 ngày' },
    { value: '12M128', label: '12M128 - 3G - 60,000 đ/360 ngày' },
    { value: 'M128', label: 'M128 - 3G - 5,000 đ/30 ngày' },
]

export const typeCustomerOptions = [
    { value: 'CMND/CCCD', label: 'CMND/CCCD' },
    { value: 'Passport', label: 'Passport' },
]

export const typeNoteCustomerOptions = [
    { value: 'Đại lý', label: 'Đại lý' },
    { value: 'Không phải chủ xe', label: 'Không phải chủ xe' },
    { value: 'Khách gọi lại sau', label: 'Khách gọi lại sau' },
    { value: 'Không nghe máy', label: 'Không nghe máy' },
    { value: 'Không có nhu cầu', label: 'Không có nhu cầu' },
    { value: 'Đã mua SIM mới', label: 'Đã mua SIM mới' },
    { value: 'Không phải chủ xe', label: 'Không phải chủ xe' },
]

export const permissionOptions = [
    // Company
    {
        label: 'Truy vấn danh sách công ty',
        value: 'GET::/api/otek-dashboard/companies',
        group: GROUP_PERMISSIONS.companies,
        name: 'Nhóm quyền quản lý công ty',
    },
    {
        label: 'Thêm công ty mới',
        value: 'POST::/api/otek-dashboard/companies',
        group: GROUP_PERMISSIONS.companies,
        name: 'Nhóm quyền quản lý công ty',
    },
    {
        label: 'Cập nhật thông tin công ty',
        value: 'PATCH::/api/otek-dashboard/companies',
        group: GROUP_PERMISSIONS.companies,
        name: 'Nhóm quyền quản lý công ty',
    },
    {
        label: 'Xoá công ty',
        value: 'DELETE::/api/otek-dashboard/companies',
        group: GROUP_PERMISSIONS.companies,
        name: 'Nhóm quyền quản lý công ty',
    },

    // Package
    {
        label: 'Truy vấn danh sách gói cước',
        value: 'GET::/api/otek-dashboard/packages',
        group: GROUP_PERMISSIONS.packages,
        name: 'Nhóm quyền quản lý gói cước',
    },
    {
        label: 'Thêm các gói cước mới',
        value: 'POST::/api/otek-dashboard/packages/add-list-packages',
        group: GROUP_PERMISSIONS.packages,
        name: 'Nhóm quyền quản lý gói cước',
    },
    {
        label: 'Xoá gói cước',
        value: 'DELETE::/api/otek-dashboard/packages',
        group: GROUP_PERMISSIONS.packages,
        name: 'Nhóm quyền quản lý gói cước',
    },
    {
        label: 'Truy vấn danh sách gói cước đối tác',
        value: 'GET::/api/otek-dashboard/partner-packages',
        group: GROUP_PERMISSIONS.packages,
        name: 'Nhóm quyền quản lý gói cước',
    },
    {
        label: 'Thêm các gói cước đối tác mới',
        value: 'POST::/api/otek-dashboard/partner-packages/add-list-partner-packages',
        group: GROUP_PERMISSIONS.packages,
        name: 'Nhóm quyền quản lý gói cước',
    },
    {
        label: 'Xoá gói cước đối tác',
        value: 'DELETE::/api/otek-dashboard/partner-packages',
        group: GROUP_PERMISSIONS.packages,
        name: 'Nhóm quyền quản lý gói cước',
    },
    {
        label: 'Truy vấn danh sách gói cước tài khoản gốc',
        value: 'GET::/api/otek-dashboard/partner-packages/get-list-packages-tkg-dashboard',
        group: GROUP_PERMISSIONS.packages,
        name: 'Nhóm quyền quản lý gói cước',
    },
    {
        label: 'Truy vấn danh sách gói cước data sponsor',
        value: 'GET::/api/otek-dashboard/partner-packages/get-list-packages-sponsor',
        group: GROUP_PERMISSIONS.packages,
        name: 'Nhóm quyền quản lý gói cước',
    },
    {
        label: 'Truy vấn danh sách gói cước đấu nối',
        value: 'GET::/api/otek-dashboard/partner-packages/get-list-packages-connection',
        group: GROUP_PERMISSIONS.packages,
        name: 'Nhóm quyền quản lý gói cước',
    },

    // Telegram
    {
        label: 'Truy vấn danh sách telegram',
        value: 'GET::/api/otek-dashboard/telegrams',
        group: GROUP_PERMISSIONS.telegrams,
        name: 'Nhóm quyền quản lý telegram',
    },
    {
        label: 'Thêm telegram mới',
        value: 'POST::/api/otek-dashboard/telegrams',
        group: GROUP_PERMISSIONS.telegrams,
        name: 'Nhóm quyền quản lý telegram',
    },
    {
        label: 'Cập nhật thông tin telegram',
        value: 'PATCH::/api/otek-dashboard/telegrams',
        group: GROUP_PERMISSIONS.telegrams,
        name: 'Nhóm quyền quản lý telegram',
    },
    {
        label: 'Xoá telegram',
        value: 'DELETE::/api/otek-dashboard/telegrams',
        group: GROUP_PERMISSIONS.telegrams,
        name: 'Nhóm quyền quản lý telegram',
    },

    // Transaction
    {
        label: 'Truy vấn danh sách giao dịch',
        value: 'GET::/api/otek-dashboard/transactions',
        group: GROUP_PERMISSIONS.transactions,
        name: 'Nhóm quyền quản lý giao dịch',
    },
    {
        label: 'Gia hạn giao dịch',
        value: 'PATCH::/api/otek-dashboard/transactions',
        group: GROUP_PERMISSIONS.transactions,
        name: 'Nhóm quyền quản lý giao dịch',
    },
    {
        label: 'Thêm giao dịch mới',
        value: 'POST::/api/otek-dashboard/transactions',
        group: GROUP_PERMISSIONS.transactions,
        name: 'Nhóm quyền quản lý giao dịch',
    },
    {
        label: 'Đấu nối giao dịch',
        value: 'POST::/api/otek-dashboard/transactions/connect',
        group: GROUP_PERMISSIONS.transactions,
        name: 'Nhóm quyền quản lý giao dịch',
    },

    // User
    {
        label: 'Truy vấn danh sách quản trị viên',
        value: 'GET::/api/otek-dashboard/users',
        group: GROUP_PERMISSIONS.users,
        name: 'Nhóm quyền quản lý quản trị viên',
    },
    {
        label: 'Thêm quản trị viên mới',
        value: 'POST::/api/otek-dashboard/users',
        group: GROUP_PERMISSIONS.users,
        name: 'Nhóm quyền quản lý quản trị viên',
    },
    {
        label: 'Cập nhật thông tin quản trị viên',
        value: 'PATCH::/api/otek-dashboard/users',
        group: GROUP_PERMISSIONS.users,
        name: 'Nhóm quyền quản lý quản trị viên',
    },
    {
        label: 'Xoá quản trị viên',
        value: 'DELETE::/api/otek-dashboard/users',
        group: GROUP_PERMISSIONS.users,
        name: 'Nhóm quyền quản lý quản trị viên',
    },

    // Role
    {
        label: 'Truy vấn danh sách quyền',
        value: 'GET::/api/otek-dashboard/base-permissions',
        group: GROUP_PERMISSIONS.roles,
        name: 'Nhóm quyền quản lý phân quyền',
    },
    {
        label: 'Truy vấn danh sách phân quyền',
        value: 'GET::/api/otek-dashboard/roles',
        group: GROUP_PERMISSIONS.roles,
        name: 'Nhóm quyền quản lý phân quyền',
    },
    {
        label: 'Thêm phân quyền mới',
        value: 'POST::/api/otek-dashboard/roles',
        group: GROUP_PERMISSIONS.roles,
        name: 'Nhóm quyền quản lý phân quyền',
    },
    {
        label: 'Cập nhật thông tin phân quyền',
        value: 'PATCH::/api/otek-dashboard/roles',
        group: GROUP_PERMISSIONS.roles,
        name: 'Nhóm quyền quản lý phân quyền',
    },
    {
        label: 'Xoá phân quyền',
        value: 'DELETE::/api/otek-dashboard/roles',
        group: GROUP_PERMISSIONS.roles,
        name: 'Nhóm quyền quản lý phân quyền',
    },

    // Sim Passports
    {
        label: 'Truy vấn danh sách sim passports',
        value: 'GET::/api/otek-dashboard/sim-passports',
        group: GROUP_PERMISSIONS.sim_passports,
        name: 'Nhóm quyền quản lý sim passports',
    },
    {
        label: 'Cập nhật thông tin sim passports',
        value: 'PATCH::/api/otek-dashboard/sim-passports',
        group: GROUP_PERMISSIONS.sim_passports,
        name: 'Nhóm quyền quản lý sim passports',
    },

    // Mbh Topups
    {
        label: 'Kiểm tra số dư',
        value: 'GET::/api/otek-dashboard/mbh-topups/check-balance-topup',
        group: GROUP_PERMISSIONS.mbh_topups,
        name: 'Nhóm quyền quản lý nạp tiền',
    },
    {
        label: 'Truy vấn danh sách đơn hàng',
        value: 'GET::/api/otek-dashboard/mbh-topups/get-list-order-topups',
        group: GROUP_PERMISSIONS.mbh_topups,
        name: 'Nhóm quyền quản lý nạp tiền',
    },
    {
        label: 'Truy vấn danh sách giao dịch bằng mã đơn hàng',
        value: 'GET::/api/otek-dashboard/mbh-topups/get-list-transaction-topups-by-order-id',
        group: GROUP_PERMISSIONS.mbh_topups,
        name: 'Nhóm quyền quản lý nạp tiền',
    },
    {
        label: 'Kiểm tra giao dịch trên hệ thống',
        value: 'GET::/api/otek-dashboard/mbh-topups/check-transaction-topup-on-resource',
        group: GROUP_PERMISSIONS.mbh_topups,
        name: 'Nhóm quyền quản lý nạp tiền',
    },
    {
        label: 'Kiểm tra giao dịch bên đối tác',
        value: 'GET::/api/otek-dashboard/mbh-topups/check-transaction-topup-on-adapter',
        group: GROUP_PERMISSIONS.mbh_topups,
        name: 'Nhóm quyền quản lý nạp tiền',
    },
    {
        label: 'Nạp tiền bằng file excel',
        value: 'POST::/api/otek-dashboard/mbh-topups/upload-order-topup-by-excel',
        group: GROUP_PERMISSIONS.mbh_topups,
        name: 'Nhóm quyền quản lý nạp tiền',
    },
    {
        label: 'Nạp tiền cho số điện thoại',
        value: 'POST::/api/otek-dashboard/mbh-topups/topup-by-phone',
        group: GROUP_PERMISSIONS.mbh_topups,
        name: 'Nhóm quyền quản lý nạp tiền',
    },
    {
        label: 'Nạp tiền bằng mã đơn hàng',
        value: 'POST::/api/otek-dashboard/mbh-topups/topup-by-order-id',
        group: GROUP_PERMISSIONS.mbh_topups,
        name: 'Nhóm quyền quản lý nạp tiền',
    },

    // Mbh Tkg Dashboard
    {
        label: 'Truy vấn danh sách gói cước tài khoản gốc',
        value: 'GET::/api/otek-dashboard/mbh-tkg-dashboard/get-dynamic-partner-offer',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard,
        name: 'Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: 'Truy vấn danh sách đơn hàng tài khoản gốc',
        value: 'GET::/api/otek-dashboard/mbh-tkg-dashboard/get-list-order-tkg-dashboard',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard,
        name: 'Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: 'Truy vấn danh sách giao dịch tài khoản gốc bằng mã đơn hàng',
        value: 'GET::/api/otek-dashboard/mbh-tkg-dashboard/get-list-transaction-tkg-dashboard-by-order-id',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard,
        name: 'Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: 'Kiểm tra giao dịch tài khoản gốc trên hệ thống',
        value: 'GET::/api/otek-dashboard/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-resource',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard,
        name: 'Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: 'Kiểm tra giao dịch tài khoản gốc bên đối tác',
        value: 'GET::/api/otek-dashboard/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-adapter',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard,
        name: 'Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: 'Nạp tiền tài khoản gốc bằng file excel',
        value: 'POST::/api/otek-dashboard/mbh-tkg-dashboard/upload-order-tkg-dashboard-by-excel',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard,
        name: 'Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: 'Nạp tiền tài khoản gốc cho số điện thoại',
        value: 'POST::/api/otek-dashboard/mbh-tkg-dashboard/tkg-dashboard-by-phone',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard,
        name: 'Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: 'Nạp tiền tài khoản gốc bằng mã đơn hàng',
        value: 'POST::/api/otek-dashboard/mbh-tkg-dashboard/tkg-dashboard-by-order-id',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard,
        name: 'Nhóm quyền quản lý tài khoản gốc',
    },

    // Data Sponsor
    {
        label: 'Truy vấn danh sách gói cước data sponsor',
        value: 'GET::/api/otek-dashboard/data-sponsor/get-dynamic-partner-offer-data-sponsor',
        group: GROUP_PERMISSIONS.data_sponsor,
        name: 'Nhóm quyền quản lý data sponsor',
    },
    {
        label: 'Truy vấn danh sách đơn hàng data sponsor',
        value: 'GET::/api/otek-dashboard/data-sponsor/get-list-order-data-sponsor',
        group: GROUP_PERMISSIONS.data_sponsor,
        name: 'Nhóm quyền quản lý data sponsor',
    },
    {
        label: 'Truy vấn danh sách giao dịch data sponsor bằng mã đơn hàng',
        value: 'GET::/api/otek-dashboard/data-sponsor/get-list-transaction-data-sponsor-by-order-id',
        group: GROUP_PERMISSIONS.data_sponsor,
        name: 'Nhóm quyền quản lý data sponsor',
    },
    {
        label: 'Kiểm tra giao dịch data sponsor trên hệ thống',
        value: 'GET::/api/otek-dashboard/data-sponsor/check-transaction-data-sponsor-on-resource',
        group: GROUP_PERMISSIONS.data_sponsor,
        name: 'Nhóm quyền quản lý data sponsor',
    },
    {
        label: 'Kiểm tra giao dịch data sponsor bên đối tác',
        value: 'GET::/api/otek-dashboard/data-sponsor/check-transaction-data-sponsor-on-adapter',
        group: GROUP_PERMISSIONS.data_sponsor,
        name: 'Nhóm quyền quản lý data sponsor',
    },
    {
        label: 'Nạp tiền data sponsor bằng file excel',
        value: 'POST::/api/otek-dashboard/data-sponsor/upload-order-data-sponsor-by-excel',
        group: GROUP_PERMISSIONS.data_sponsor,
        name: 'Nhóm quyền quản lý data sponsor',
    },
    {
        label: 'Nạp tiền data sponsor cho số điện thoại',
        value: 'POST::/api/otek-dashboard/data-sponsor/data-sponsor-by-phone',
        group: GROUP_PERMISSIONS.data_sponsor,
        name: 'Nhóm quyền quản lý data sponsor',
    },
    {
        label: 'Nạp tiền data sponsor bằng mã đơn hàng',
        value: 'POST::/api/otek-dashboard/data-sponsor/data-sponsor-by-order-id',
        group: GROUP_PERMISSIONS.data_sponsor,
        name: 'Nhóm quyền quản lý data sponsor',
    },
    {
        label: 'Kiểm tra số dư data sponsor',
        value: 'GET::/api/otek-dashboard/data-sponsor/check-wallet-balance-postpaid',
        group: GROUP_PERMISSIONS.data_sponsor,
        name: 'Nhóm quyền quản lý data sponsor',
    },

    // User Partner
    {
        label: '[Đối tác] Truy vấn danh sách quản trị viên',
        value: 'GET::/api/otek-dashboard/user-partners/get-list-user-partners',
        group: GROUP_PERMISSIONS.user_partner,
        name: '[Đối tác] Nhóm quyền quản lý quản trị viên',
    },
    {
        label: '[Đối tác] Thêm quản trị viên mới',
        value: 'POST::/api/otek-dashboard/user-partners/add-user-partner',
        group: GROUP_PERMISSIONS.user_partner,
        name: '[Đối tác] Nhóm quyền quản lý quản trị viên',
    },
    {
        label: '[Đối tác] Cập nhật thông tin quản trị viên',
        value: 'PATCH::/api/otek-dashboard/user-partners/update-user-partner',
        group: GROUP_PERMISSIONS.user_partner,
        name: '[Đối tác] Nhóm quyền quản lý quản trị viên',
    },
    {
        label: '[Đối tác] Xoá quản trị viên',
        value: 'DELETE::/api/otek-dashboard/user-partners/delete-user-partner',
        group: GROUP_PERMISSIONS.user_partner,
        name: '[Đối tác] Nhóm quyền quản lý quản trị viên',
    },

    // Mbh Tkg Dashboard Partner
    {
        label: '[Đối tác] Truy vấn danh sách gói cước tài khoản gốc',
        value: 'GET::/api/partner/mbh-tkg-dashboard/get-list-packages-tkg-dashboard',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard_partner,
        name: '[Đối tác] Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: '[Đối tác] Truy vấn danh sách đơn hàng tài khoản gốc',
        value: 'GET::/api/partner/mbh-tkg-dashboard/get-list-order-tkg-dashboard',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard_partner,
        name: '[Đối tác] Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: '[Đối tác] Truy vấn danh sách giao dịch tài khoản gốc bằng mã đơn hàng',
        value: 'GET::/api/partner/mbh-tkg-dashboard/get-list-transaction-tkg-dashboard-by-order-id',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard_partner,
        name: '[Đối tác] Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: '[Đối tác] Kiểm tra giao dịch tài khoản gốc trên hệ thống',
        value: 'GET::/api/partner/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-resource',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard_partner,
        name: '[Đối tác] Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: '[Đối tác] Kiểm tra giao dịch tài khoản gốc bên đối tác',
        value: 'GET::/api/partner/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-adapter',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard_partner,
        name: '[Đối tác] Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: '[Đối tác] Nạp tiền tài khoản gốc bằng file excel',
        value: 'POST::/api/partner/mbh-tkg-dashboard/upload-order-tkg-dashboard-by-excel',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard_partner,
        name: '[Đối tác] Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: '[Đối tác] Nạp tiền tài khoản gốc cho số điện thoại',
        value: 'POST::/api/partner/mbh-tkg-dashboard/tkg-dashboard-by-phone',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard_partner,
        name: '[Đối tác] Nhóm quyền quản lý tài khoản gốc',
    },
    {
        label: '[Đối tác] Nạp tiền tài khoản gốc bằng mã đơn hàng',
        value: 'POST::/api/partner/mbh-tkg-dashboard/tkg-dashboard-by-order-id',
        group: GROUP_PERMISSIONS.mbh_tkg_dashboard_partner,
        name: '[Đối tác] Nhóm quyền quản lý tài khoản gốc',
    },

    // Transaction Connection
    {
        label: 'Truy vấn danh sách giao dịch đấu nối',
        value: 'GET::/api/otek-dashboard/transaction-connection/get-list-transaction-connection',
        group: GROUP_PERMISSIONS.transaction_connection,
        name: 'Nhóm quyền quản lý giao dịch đấu nối',
    },
    {
        label: 'Thêm danh sách giao dịch đấu nối',
        value: 'POST::/api/otek-dashboard/transaction-connection/add-list-transaction-connection',
        group: GROUP_PERMISSIONS.transaction_connection,
        name: 'Nhóm quyền quản lý giao dịch đấu nối',
    },
    {
        label: 'Cập nhật danh sách giao dịch đấu nối',
        value: 'PUT::/api/otek-dashboard/transaction-connection/update-list-transaction-connection',
        group: GROUP_PERMISSIONS.transaction_connection,
        name: 'Nhóm quyền quản lý giao dịch đấu nối',
    },
    {
        label: 'Thêm giao dịch đấu nối bằng cú pháp',
        value: 'POST::/api/otek-dashboard/transaction-connection/add-transaction-connection',
        group: GROUP_PERMISSIONS.transaction_connection,
        name: 'Nhóm quyền quản lý giao dịch đấu nối',
    },
    {
        label: 'Truy vấn danh sách giao dịch hết hạn',
        value: 'GET::/api/otek-dashboard/transaction-connection/get-list-transaction-connection-expire',
        group: GROUP_PERMISSIONS.transaction_connection,
        name: 'Nhóm quyền quản lý giao dịch đấu nối',
    },

    // Transaction Connection
    {
        label: 'Truy vấn thông tin doanh thu',
        value: 'GET::/api/otek-dashboard/statistical-service/get-revenue-connection',
        group: GROUP_PERMISSIONS.statistical_service,
        name: 'Nhóm quyền quản lý thống kê',
    },
    {
        label: 'Truy vấn thông tin đấu nối theo loại',
        value: 'GET::/api/otek-dashboard/statistical-service/get-statistical-type-connection',
        group: GROUP_PERMISSIONS.statistical_service,
        name: 'Nhóm quyền quản lý thống kê',
    },
    {
        label: 'Truy vấn thông tin đấu nối theo ngày',
        value: 'GET::/api/otek-dashboard/statistical-service/get-statistical-date-connection',
        group: GROUP_PERMISSIONS.statistical_service,
        name: 'Nhóm quyền quản lý thống kê',
    },
    {
        label: 'Truy vấn thông tin đấu nối theo chu kỳ gói',
        value: 'GET::/api/otek-dashboard/statistical-service/get-statistical-pack-duration-connection',
        group: GROUP_PERMISSIONS.statistical_service,
        name: 'Nhóm quyền quản lý thống kê',
    },
    {
        label: 'Truy vấn thông tin đấu nối hết hạn theo loại',
        value: 'GET::/api/otek-dashboard/statistical-service/get-statistical-type-expiration',
        group: GROUP_PERMISSIONS.statistical_service,
        name: 'Nhóm quyền quản lý thống kê',
    },
    {
        label: 'Truy vấn thông tin đấu nối hết hạn theo ngày',
        value: 'GET::/api/otek-dashboard/statistical-service/get-statistical-date-expiration',
        group: GROUP_PERMISSIONS.statistical_service,
        name: 'Nhóm quyền quản lý thống kê',
    },

    // Customer Resource
    {
        label: 'Truy vấn danh sách khách hàng đấu nối',
        value: 'GET::/api/otek-dashboard/customer-resource/get-list-customer-resource',
        group: GROUP_PERMISSIONS.customer_resource,
        name: 'Nhóm quyền quản lý khách hàng đấu nối',
    },
    {
        label: 'Thêm danh sách khách hàng đấu nối',
        value: 'POST::/api/otek-dashboard/customer-resource/add-list-customer-resource',
        group: GROUP_PERMISSIONS.customer_resource,
        name: 'Nhóm quyền quản lý khách hàng đấu nối',
    },
    {
        label: 'Cập nhật danh sách khách hàng đấu nối',
        value: 'PUT::/api/otek-dashboard/customer-resource/update-list-customer-resource',
        group: GROUP_PERMISSIONS.customer_resource,
        name: 'Nhóm quyền quản lý khách hàng đấu nối',
    },

    // Phone Agency
    {
        label: 'Truy vấn danh sách đại lý đấu nối',
        value: 'GET::/api/otek-dashboard/phone-agencies/get-list-phone-agencies',
        group: GROUP_PERMISSIONS.phone_agency,
        name: 'Nhóm quyền quản lý đại lý đấu nối',
    },
    {
        label: 'Thêm danh sách đại lý đấu nối',
        value: 'POST::/api/otek-dashboard/phone-agencies/add-list-phone-agencies',
        group: GROUP_PERMISSIONS.phone_agency,
        name: 'Nhóm quyền quản lý đại lý đấu nối',
    },
    {
        label: 'Cập nhật danh sách đại lý đấu nối',
        value: 'PUT::/api/otek-dashboard/phone-agencies/update-list-phone-agencies',
        group: GROUP_PERMISSIONS.phone_agency,
        name: 'Nhóm quyền quản lý đại lý đấu nối',
    },
]

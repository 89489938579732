import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Form, Input, InputNumber, Modal, Select, Space, TableProps } from 'antd'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { apiDeletePartnerPackage, apiGetListCompanies, apiGetListPackages, apiGetListPartnerPackages } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import { IPackage, IPartnerPackage, IPartnerPackages } from '../../models/package.modal'
import { Link } from 'react-router-dom';
import { PACKAGE, CREATE, DETAIL } from '../../assets/constants/route_path'
import { toastError, toastSuccess } from '../../utils/toast'
import { formatCurrency } from '../../utils/string-utils'
import FormSearch from '../../components/form'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
import { ICompany } from '../../models/company.modal'
import { filterOptionByLabel } from '../../utils/filter-utils'
import ViewPartner from './ViewPartner'
import Action from './Action'
import { formatDate } from '../../utils/entity-utils'
import { partnerPackageTypeOptions } from '../../assets/constants/default_options'

export default function PackagePartner() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [companyValue, setCompanyValue] = useState({
        id: 0,
        name: '',
        code: '',
    })
    const [packages, setPackages] = useState<IPartnerPackages>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        name: '',
        code: '',
        price: 0,
        company: '',
        type: '',
    })
    const [modalDelete, setModalDelete] = useState<{ data: IPartnerPackage | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: 'STT',
            render: (value, record, index) => ++index + (pageSize * (currentPage - 1)),
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
        },
        {
            key: 'code',
            title: 'Mã gói cước',
            dataIndex: 'code',
        },
        {
            key: 'price',
            title: 'Giá bán',
            dataIndex: 'price',
            render: (price) => formatCurrency(price || 0),
        },
        {
            key: 'price',
            title: 'Giá trả lại',
            dataIndex: 'price_cashback',
            render: (price_cashback) => formatCurrency(price_cashback || 0),
        },
        {
            key: 'price_discount',
            title: 'Hoa hồng Viettel',
            dataIndex: 'price_discount',
            render: (price_discount) => formatCurrency(price_discount || 0),
        },
        {
            key: 'company',
            title: 'Công ty',
            render: (_, data: IPartnerPackage) => data.company?.name || Number(data?.company_id),
        },
        {
            key: 'package',
            title: 'Gói cước',
            render: (_, data: IPartnerPackage) => data.package?.name || Number(data?.package_id),
        },
        {
            key: 'type',
            title: 'Loại gói cước',
            dataIndex: 'type',
        },
        {
            key: 'modify',
            title: 'Cập nhật',
            dataIndex: 'modify',
            render: (modify) => formatDate(modify),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: IPackage) => (
                <Action
                    canDelete={account.canDeletePartnerPackage}
                    hanldeDelete={() => setModalDelete({ data, isOpen: true })}
                    handleView={() => setModalView({ data, isOpen: true })}
                />
            ),
        },
    ]

    const fetchCompanies = useCallback(async () => {
        try {
            const resCompanies = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resCompanies.data != null) {
                const options = resCompanies.data.companies.map((item: ICompany) => ({
                    label: `${item.id} - ${item.name}`,
                    value: `${item.id}::${item.name}::${item.code}`
                }))
                setCompanyOptions(options)
            } else {
                setCompanyOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetListPartnerPackages({
                limit: pageSize,
                offset: pageSize * (currentPage - 1),
                name: search.name,
                code: search.code,
                price: search.price,
                company_id: Number(search.company?.split('::')[0]) || 0,
                type: search.type,
            })
            if (resp.data === null) {
                setPackages({})
                return
            }
            setPackages(resp.data)
            handleMappingPartnerPackages(resp.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    const handleMappingPartnerPackages = async (respPartner: any) => {
        try {
            const [respPackages, respCompanies]: any = await Promise.all([
                apiGetListPackages({ limit: defaultParams.MAX_LIMIT }),
                apiGetListCompanies({ limit: defaultParams.MAX_LIMIT }),
            ])
            if (respPackages.data === null && respCompanies.data === null) {
                return
            }

            const resp = { count: respPartner.count, partner_packages: [] as any }
            const listPackagePartner = respPartner.partner_packages
            const listCompanies = respCompanies.data.companies
            const listPackages = respPackages.data.packages
            for (let i = 0; i < listPackagePartner.length; i++) {
                const packagePartnerInfo = listPackagePartner[i]
                const companyInfo = listCompanies.find((company: ICompany) => company.id === packagePartnerInfo.company_id)
                const packageInfo = listPackages.find((pack: IPackage) => pack.id === packagePartnerInfo.package_id)
                resp.partner_packages.push({
                    ...packagePartnerInfo,
                    company: companyInfo,
                    package: packageInfo,
                })
            }
            setPackages(resp)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
        fetchCompanies()
    }, [fetchData, fetchCompanies])

    const handleSearch = ({ name, code, price, company, type }: any) => {
        if (name || code || price || company || type) {
            setSearch({ name, code, price, company, type })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        setCompanyValue({
            id: 0,
            name: '',
            code: '',
        })
        formSearch.resetFields()
        setSearch({
            name: '',
            code: '',
            price: 0,
            company: '',
            type: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleDelete = async () => {
        try {
            setIsLoading(true)
            const resp = await apiDeletePartnerPackage(modalDelete.data?.id)
            if (resp.status === 200) {
                setModalDelete({ data: null, isOpen: false })
                toastSuccess('Xoá gói cước thành công!')
                fetchData()
            } else {
                toastError('Có lỗi xảy ra!')
            }
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleSetCompanyValue = (value: string) => {
        const [id, name, code] = value?.split('::')
        setCompanyValue({ id: Number(id), name, code })
    }

    const handleClickAddPackage = (e: any) => {
        if (!companyValue.id || !companyValue.name || !companyValue.code) {
            e.preventDefault()
            toastError('Vui lòng chọn công ty!')
        }
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='company' label='Công ty'>
                    <Select
                        onChange={handleSetCompanyValue}
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn công ty"
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='name' label='Tên gói cước'>
                    <Input />
                </Form.Item>
                <Form.Item name='code' label='Mã gói cước'>
                    <Input />
                </Form.Item>
                <Form.Item name='price' label='Giá bán'>
                    <InputNumber formatter={value => formatCurrency(Number(value))} style={{ minWidth: 170 }} />
                </Form.Item>
                <Form.Item name='type' label='Loại gói cước'>
                    <Select options={partnerPackageTypeOptions} placeholder="Chọn loại gói cước" />
                </Form.Item>
            </FormSearch>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Space>Đối tác:{companyValue.name && <h3>{companyValue.name}</h3>}</Space>
                {account.canAddListPartnerPackages && (
                    <div style={{ textAlign: 'end', marginBottom: 20 }}>
                        <Link to={PACKAGE + DETAIL + CREATE + `?id=${companyValue.id}&name=${companyValue.name}&code=${companyValue.code}`}>
                            <Button onClick={handleClickAddPackage} type='primary'>Thêm gói cước</Button>
                        </Link>
                    </div>
                )}
            </div>
            <CustomTable
                namePage='gói cước'
                columns={columns}
                dataSource={packages.partner_packages}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={packages.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Xoá gói cước' open={modalDelete.isOpen}
                onCancel={() => setModalDelete({ data: null, isOpen: false })}
                footer={[
                    <Button onClick={handleDelete} type='primary' danger>
                        Xoá
                    </Button>,
                ]}>
                <Divider />
                <div>Bạn có chắc muốn xoá gói cước <b>{modalDelete.data?.name}</b>?</div>
            </Modal>
            <ViewPartner
                modalView={modalView}
                setModalView={setModalView}
            />
        </div>
    )
}

import { Line } from "react-chartjs-2"
import { colorPalette } from "../../utils/color-utils"

export default function ChartType({ totalPeriodByMonth }: any) {
    let totalByMonth = [] as any
    const labels = Array.from(
        new Set(...totalPeriodByMonth.map((period: any) => period.months.map((item: any) => {
            const total = totalByMonth.find((period: any) => period.month === item.month)
            total ? total.sum += item.sum : totalByMonth.push({
                month: item.month,
                sum: item.sum,
            })
            return item.month
        })))
    ).sort((a: any, b: any) => a - b)

    const datasets = totalPeriodByMonth.map((period: any, index: any) => {
        const label = `${period?.packDuration / 30} tháng`
        const fillData = labels.map((month) => period?.months?.find((item: any) => item.month === month) || { month, sum: 0 })
        const data = fillData?.map((month: any) => {
            const monthlyTotal = totalByMonth.find((period: any) => period.month === month.month)
            const monthSum = Number(month.sum)
            if (monthSum === 0 || monthlyTotal.sum === 0) return 0
            const percentage = ((monthSum / monthlyTotal.sum) * 100).toFixed(2)
            return parseFloat(percentage)
        })
        return {
            label: label,
            data: data,
            datalabels: {
                color: colorPalette[index],
            },
        }
    })

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Tỷ lệ đấu nối gói theo chu kỳ',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                display: true,
                borderWidth: 2,
                font: {
                    size: 14,
                    weight: 'bold',
                },
                anchor: 'end',
                align: 'top',
                formatter: (value: any) => value === 0 ? '' : `${value}%`.replace('.00', ''),
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    } as any

    return (
        <Line data={{ labels, datasets }} options={options} />
    )
}

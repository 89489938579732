const AUTH_LOGIN = '/auth/login'
const HOME = '/'

const CREATE = '/create'
const UPDATE = '/update'
const OVERVIEW = '/overview'
const HISTORY = '/history'
const ORDER = '/order'
const CONNECT = '/connect'
const SUBSCRIBER = '/subscriber'
const DETAIL = '/detail'
const DETAIL_TRANSACTION = '/detail-transaction'
const DETAIL_PACKAGE = '/detail-package'

const PROFILE = '/profile'
const COMPANY = '/company'
const PACKAGE = '/package'
const TRANSACTION = '/transaction'
const TELEGRAM = '/telegram'
const USER = '/user'
const ROLE = '/role'
const DAU_NOI = '/dau-noi'
const GIA_HAN = '/gia-han'
const SIM_PASSPORT = '/sim-passport'
const TOP_UP = '/top-up'
const ROOT_ACCOUNT = '/root-account'
const REGISTER_ADDON = '/register-addon'
const KIT_SIM = '/kit-sim'
const CARD_SIM = '/card-sim'
const CUSTOMER = '/customer'
const AGENCY = '/agency'
const TELESALE = '/telesale'
const DASHBOARD = '/dashboard'
const USER_PARTNER = '/user-partner'

export {
  AUTH_LOGIN,
  PROFILE,
  HOME,
  CREATE,
  UPDATE,
  OVERVIEW,
  HISTORY,
  ORDER,
  CONNECT,
  SUBSCRIBER,
  DETAIL,
  DETAIL_TRANSACTION,
  DETAIL_PACKAGE,
  COMPANY,
  PACKAGE,
  TRANSACTION,
  TELEGRAM,
  USER,
  ROLE,
  DAU_NOI,
  GIA_HAN,
  SIM_PASSPORT,
  TOP_UP,
  ROOT_ACCOUNT,
  REGISTER_ADDON,
  KIT_SIM,
  CARD_SIM,
  CUSTOMER,
  AGENCY,
  TELESALE,
  DASHBOARD,
  USER_PARTNER,
}

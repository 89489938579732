import {
  createBrowserRouter,
  matchRoutes,
  useLocation,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {
  NotFound,
  Home,
  LoginPage,
  AuthPage,
  ProfilePage,
  TransactionPage,
  CompanyPage,
  PackageOverviewPage,
  PackageOverviewCreate,
  TelegramPage,
  CompanyCreate,
  TelegramCreate,
  CompanyUpdate,
  TelegramUpdate,
  RolePage,
  RoleCreate,
  RoleUpdate,
  UserPage,
  UserCreate,
  UserUpdate,
  ManagementSimPassportPage,
  ManagementTopUpPage,
  TransactionCreate,
  ManagementTopUpOrder,
  ManagementTopUpHistory,
  ManagementTopUpDetailTransaction,
  RootAccountPage,
  RootAccountOrder,
  RootAccountHistory,
  RootAccountDetailTransaction,
  RootAccountDetailPackage,
  KitSimOrder,
  KitSimHistory,
  KitSimDetailTransaction,
  KitSimCustomer,
  KitSimCustomerDetail,
  CardSimOrder,
  CardSimHistory,
  CardSimDetailTransaction,
  PackagePartnerPage,
  PackagePartnerCreate,
  KitSimConnect,
  KitSimSubscriber,
  RegisterAddonPage,
  RegisterAddonOrder,
  RegisterAddonHistory,
  RegisterAddonDetailTransaction,
  RegisterAddonDetailPackage,
  UserPartnerPage,
  UserPartnerCreate,
  UserPartnerUpdate,
  DashboardDauNoi,
  DashboardGiaHan,
  KitSimAgency,
  KitSimAgencyDetail,
  KitSimTeleSale,
} from '../pages'
import {
  AUTH_LOGIN,
  HOME,
  PROFILE,
  COMPANY,
  PACKAGE,
  TRANSACTION,
  TELEGRAM,
  CREATE,
  UPDATE,
  ROLE,
  USER,
  SIM_PASSPORT,
  TOP_UP,
  DASHBOARD,
  ORDER,
  HISTORY,
  OVERVIEW,
  DETAIL_TRANSACTION,
  ROOT_ACCOUNT,
  DETAIL_PACKAGE,
  KIT_SIM,
  CARD_SIM,
  CUSTOMER,
  DETAIL,
  CONNECT,
  SUBSCRIBER,
  REGISTER_ADDON,
  USER_PARTNER,
  DAU_NOI,
  GIA_HAN,
  AGENCY,
  TELESALE,
} from '../assets/constants/route_path'
import Layout from '../layout'
import PrivateRoute from '../components/private-route'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import PermUtils from '../utils/perm-utils'

export const routes = [
  {
    path: '/auth',
    element: <AuthPage />,
    children: [
      {
        path: AUTH_LOGIN,
        element: <LoginPage />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: HOME,
        element: (
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        ),
      },
      {
        path: COMPANY,
        element: (
          <PrivateRoute>
            <CompanyPage />
          </PrivateRoute>
        ),
      },
      {
        path: COMPANY + CREATE,
        element: (
          <PrivateRoute>
            <CompanyCreate />
          </PrivateRoute>
        ),
      },
      {
        path: COMPANY + UPDATE,
        element: (
          <PrivateRoute>
            <CompanyUpdate />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGE + OVERVIEW,
        element: (
          <PrivateRoute>
            <PackageOverviewPage />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGE + OVERVIEW + CREATE,
        element: (
          <PrivateRoute>
            <PackageOverviewCreate />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGE + DETAIL,
        element: (
          <PrivateRoute>
            <PackagePartnerPage />
          </PrivateRoute>
        ),
      },
      {
        path: PACKAGE + DETAIL + CREATE,
        element: (
          <PrivateRoute>
            <PackagePartnerCreate />
          </PrivateRoute>
        ),
      },
      {
        path: TRANSACTION,
        element: (
          <PrivateRoute
          //     hasAnyFunctions={[PermUtils.canGetLisTransactionAdminResource]}
          >
            <TransactionPage />
          </PrivateRoute>
        ),
      },
      {
        path: TRANSACTION + CREATE,
        element: (
          <PrivateRoute>
            <TransactionCreate />
          </PrivateRoute>
        ),
      },
      {
        path: TELEGRAM,
        element: (
          <PrivateRoute>
            <TelegramPage />
          </PrivateRoute>
        ),
      },
      {
        path: TELEGRAM + CREATE,
        element: (
          <PrivateRoute>
            <TelegramCreate />
          </PrivateRoute>
        ),
      },
      {
        path: TELEGRAM + UPDATE,
        element: (
          <PrivateRoute>
            <TelegramUpdate />
          </PrivateRoute>
        ),
      },
      {
        path: USER,
        element: (
          <PrivateRoute>
            <UserPage />
          </PrivateRoute>
        ),
      },
      {
        path: USER + CREATE,
        element: (
          <PrivateRoute>
            <UserCreate />
          </PrivateRoute>
        ),
      },
      {
        path: USER + UPDATE,
        element: (
          <PrivateRoute>
            <UserUpdate />
          </PrivateRoute>
        ),
      },
      {
        path: ROLE,
        element: (
          <PrivateRoute>
            <RolePage />
          </PrivateRoute>
        ),
      },
      {
        path: ROLE + CREATE,
        element: (
          <PrivateRoute>
            <RoleCreate />
          </PrivateRoute>
        ),
      },
      {
        path: ROLE + UPDATE,
        element: (
          <PrivateRoute>
            <RoleUpdate />
          </PrivateRoute>
        ),
      },
      {
        path: PROFILE,
        element: (
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        )
      },
      {
        path: SIM_PASSPORT,
        element: (
          <PrivateRoute>
            <ManagementSimPassportPage />
          </PrivateRoute>
        )
      },
      {
        path: TOP_UP + OVERVIEW,
        element: (
          <PrivateRoute>
            <ManagementTopUpPage />
          </PrivateRoute>
        )
      },
      {
        path: TOP_UP + ORDER,
        element: (
          <PrivateRoute>
            <ManagementTopUpOrder />
          </PrivateRoute>
        )
      },
      {
        path: TOP_UP + HISTORY,
        element: (
          <PrivateRoute>
            <ManagementTopUpHistory />
          </PrivateRoute>
        )
      },
      {
        path: TOP_UP + DETAIL_TRANSACTION,
        element: (
          <PrivateRoute>
            <ManagementTopUpDetailTransaction />
          </PrivateRoute>
        )
      },
      {
        path: ROOT_ACCOUNT + OVERVIEW,
        element: (
          <PrivateRoute>
            <RootAccountPage />
          </PrivateRoute>
        )
      },
      {
        path: ROOT_ACCOUNT + ORDER,
        element: (
          <PrivateRoute>
            <RootAccountOrder />
          </PrivateRoute>
        )
      },
      {
        path: ROOT_ACCOUNT + HISTORY,
        element: (
          <PrivateRoute>
            <RootAccountHistory />
          </PrivateRoute>
        )
      },
      {
        path: ROOT_ACCOUNT + DETAIL_TRANSACTION,
        element: (
          <PrivateRoute>
            <RootAccountDetailTransaction />
          </PrivateRoute>
        )
      },
      {
        path: ROOT_ACCOUNT + DETAIL_PACKAGE,
        element: (
          <PrivateRoute>
            <RootAccountDetailPackage />
          </PrivateRoute>
        )
      },
      {
        path: REGISTER_ADDON + OVERVIEW,
        element: (
          <PrivateRoute>
            <RegisterAddonPage />
          </PrivateRoute>
        )
      },
      {
        path: REGISTER_ADDON + ORDER,
        element: (
          <PrivateRoute>
            <RegisterAddonOrder />
          </PrivateRoute>
        )
      },
      {
        path: REGISTER_ADDON + HISTORY,
        element: (
          <PrivateRoute>
            <RegisterAddonHistory />
          </PrivateRoute>
        )
      },
      {
        path: REGISTER_ADDON + DETAIL_TRANSACTION,
        element: (
          <PrivateRoute>
            <RegisterAddonDetailTransaction />
          </PrivateRoute>
        )
      },
      {
        path: REGISTER_ADDON + DETAIL_PACKAGE,
        element: (
          <PrivateRoute>
            <RegisterAddonDetailPackage />
          </PrivateRoute>
        )
      },
      {
        path: KIT_SIM + CONNECT,
        element: (
          <PrivateRoute>
            <KitSimConnect />
          </PrivateRoute>
        )
      },
      {
        path: KIT_SIM + ORDER,
        element: (
          <PrivateRoute>
            <KitSimOrder />
          </PrivateRoute>
        )
      },
      {
        path: KIT_SIM + HISTORY,
        element: (
          <PrivateRoute>
            <KitSimHistory />
          </PrivateRoute>
        )
      },
      {
        path: KIT_SIM + DETAIL_TRANSACTION,
        element: (
          <PrivateRoute>
            <KitSimDetailTransaction />
          </PrivateRoute>
        )
      },
      {
        path: KIT_SIM + SUBSCRIBER,
        element: (
          <PrivateRoute>
            <KitSimSubscriber />
          </PrivateRoute>
        )
      },
      {
        path: KIT_SIM + CUSTOMER,
        element: (
          <PrivateRoute>
            <KitSimCustomer />
          </PrivateRoute>
        )
      },
      {
        path: KIT_SIM + CUSTOMER + DETAIL,
        element: (
          <PrivateRoute>
            <KitSimCustomerDetail />
          </PrivateRoute>
        )
      },
      {
        path: KIT_SIM + AGENCY,
        element: (
          <PrivateRoute>
            <KitSimAgency />
          </PrivateRoute>
        )
      },
      {
        path: KIT_SIM + AGENCY + DETAIL,
        element: (
          <PrivateRoute>
            <KitSimAgencyDetail />
          </PrivateRoute>
        )
      },
      {
        path: KIT_SIM + TELESALE,
        element: (
          <PrivateRoute>
            <KitSimTeleSale />
          </PrivateRoute>
        )
      },
      {
        path: CARD_SIM + ORDER,
        element: (
          <PrivateRoute>
            <CardSimOrder />
          </PrivateRoute>
        )
      },
      {
        path: CARD_SIM + HISTORY,
        element: (
          <PrivateRoute>
            <CardSimHistory />
          </PrivateRoute>
        )
      },
      {
        path: CARD_SIM + DETAIL_TRANSACTION,
        element: (
          <PrivateRoute>
            <CardSimDetailTransaction />
          </PrivateRoute>
        )
      },
      {
        path: COMPANY + USER_PARTNER,
        element: (
          <PrivateRoute>
            <UserPartnerPage />
          </PrivateRoute>
        ),
      },
      {
        path: COMPANY + USER_PARTNER + CREATE,
        element: (
          <PrivateRoute>
            <UserPartnerCreate />
          </PrivateRoute>
        ),
      },
      {
        path: COMPANY + USER_PARTNER + UPDATE,
        element: (
          <PrivateRoute>
            <UserPartnerUpdate />
          </PrivateRoute>
        ),
      },
      {
        path: DASHBOARD + DAU_NOI,
        element: (
          <PrivateRoute>
            <DashboardDauNoi />
          </PrivateRoute>
        )
      },
      {
        path: DASHBOARD + GIA_HAN,
        element: (
          <PrivateRoute>
            <DashboardGiaHan />
          </PrivateRoute>
        )
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]

export const useMatchedRoute = () => {
  const location = useLocation()
  const matchedRoutes = matchRoutes(routes, location)

  if (!matchedRoutes || matchedRoutes.length === 0) {
    return undefined
  }

  // Sort by accuracy desc
  matchedRoutes.reverse()

  for (let matchedRoute of matchedRoutes) {
    const matchedRoutePath = matchedRoute.route.path
    // index route will not contain path attr -> get next route (parent route)
    if (matchedRoutePath) {
      return matchedRoutePath
    }
  }

  return undefined
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
export const router = sentryCreateBrowserRouter(routes)

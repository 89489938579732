import { Button, Divider, Form, Input, InputNumber, Modal, Popconfirm, Select, Space, Table, TableProps } from "antd"
import { PlusOutlined, CloseOutlined } from "@ant-design/icons"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonBack from "../../components/button/ButtonBack"
import { ICreatePackage } from "../../models/package.modal"
import { useLoading } from "../../context/LoadingContext"
import { apiCreateListPackages } from "../../api"
import { packageTypeOptions } from "../../assets/constants/default_options"
import { formatCurrency } from "../../utils/string-utils"
import { useState } from "react"

export default function PackageOverviewCreate() {
    const [listAddPackages, setListAddPackages] = useState<any>([])
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [formCreate] = Form.useForm()
    const { setIsLoading } = useLoading()

    const handleAddPackage = async (values: ICreatePackage) => {
        try {
            setIsLoading(true)
            setListAddPackages([...listAddPackages, values])
            toastSuccess('Đã thêm gói cước vào danh sách!')
            formCreate.resetFields()
        } catch (error) {
            toastError('Vui lòng kiểm tra lại thông tin!')
        } finally {
            setIsLoading(false)
        }
    }

    const packageColumns: TableProps<any>['columns'] = [
        {
            key: 'stt',
            title: 'STT',
            render: (_text, _record, index) => index + 1,
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
        },
        {
            key: 'code',
            title: 'Mã gói cước',
            dataIndex: 'code',
        },
        {
            key: 'price',
            title: 'Giá gốc',
            dataIndex: 'price',
            render: (price) => formatCurrency(price),
        },
        {
            key: 'numberDay',
            title: 'Hạn sử dụng',
            dataIndex: 'numberDay',
            render: (numberDay) => `${numberDay} ngày`
        },
        {
            key: 'type',
            title: 'Loại',
            dataIndex: 'type',
        },
        {
            key: 'action',
            render: (_text, _record, index) => (
                <Popconfirm
                    title="Xoá gói cước"
                    description="Xoá gói cước khỏi danh sách?"
                    onConfirm={() => {
                        const newList = listAddPackages.filter((_: any, i: any) => i !== index)
                        setListAddPackages(newList)
                        toastError('Đã xoá gói cước khỏi danh sách!')
                    }}
                    okText="Xác nhận"
                    cancelText="Huỷ bỏ"
                >
                    <Button type="primary" danger>Xoá</Button>
                </Popconfirm>
            )
        }
    ]

    const handleCreate = async () => {
        try {
            setIsLoading(true)
            const resp = await apiCreateListPackages({ packages: listAddPackages })
            if (resp.status === 200) {
                toastSuccess(`Thêm thành công ${listAddPackages.length} gói cước mới!`)
                setListAddPackages([])
                setIsOpenModal(false)
            } else {
                toastError('Có lỗi xảy ra!')
            }
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <Form form={formCreate} onFinish={handleAddPackage} layout="vertical" autoComplete="off">
                <div style={{ display: 'flex', gap: 20 }}>
                    <Form.Item style={{ flex: 1 }} name='name' label='Tên gói cước'
                        rules={[{ required: true, message: 'Vui lòng nhập tên gói cước!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item style={{ flex: 1 }} name='code' label='Mã gói cước'
                        rules={[{ required: true, message: 'Vui lòng nhập mã gói cước!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item style={{ flex: 1 }} name='numberDay' label='Hạn sử dụng'
                        rules={[{ required: true, message: 'Vui lòng nhập hạn sử dụng!' }]}>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </div>
                <div style={{ display: 'flex', gap: 20 }}>
                    <Form.Item style={{ flex: 1 }} name='price' label='Giá gốc'
                        rules={[{ required: true, message: 'Vui lòng nhập giá gốc!' }]}>
                        <InputNumber formatter={value => formatCurrency(Number(value))} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item style={{ flex: 1 }} name='type' label='Chu kỳ tính cước'
                        rules={[{ required: true, message: 'Vui lòng chọn chu kỳ tính cước!' }]}>
                        <Select options={packageTypeOptions} placeholder="Chọn chu kỳ tính cước" />
                    </Form.Item>
                    <Form.Item style={{ flex: 1 }} name='note' label='Ghi chú'>
                        <Input />
                    </Form.Item>
                </div>
                <Form.Item name='description' label='Mô tả'>
                    <Input.TextArea />
                </Form.Item>
                <div style={{ textAlign: 'end' }}>
                    <Space>
                        <Button htmlType="submit" icon={<PlusOutlined />}
                            style={{ background: '#008965', color: 'white' }}>
                            <b>Thêm</b>
                        </Button>
                        <Button htmlType="reset" icon={<CloseOutlined />}
                            style={{ background: '#c93c3c', color: 'white' }}>
                            <b>Xoá</b>
                        </Button>
                    </Space>
                </div>
            </Form>
            <Divider />
            <div style={{ marginTop: 20, overflow: 'auto' }}>
                <div style={{ maxHeight: 360, overflow: 'auto' }}>
                    <Table
                        rowKey={(_, index: any) => index}
                        style={{ overflow: 'auto' }}
                        columns={packageColumns}
                        dataSource={listAddPackages}
                        pagination={false}
                        footer={() => (
                            <div>Tổng {listAddPackages.length || '0'} gói cước</div>
                        )}
                    />
                </div>
                <div style={{ textAlign: 'end', marginTop: 20 }}>
                    <Button
                        disabled={listAddPackages.length === 0}
                        onClick={() => setIsOpenModal(true)}
                        type="primary"
                    >
                        Xác nhận
                    </Button>
                </div>
            </div>
            <Modal title='Thêm gói cước' open={isOpenModal}
                onCancel={() => setIsOpenModal(false)}
                footer={[
                    <Button onClick={handleCreate} type='primary'>
                        Thêm
                    </Button>,
                ]}>
                Bạn có chắc chắn muốn thêm các gói cước?
            </Modal>
        </div>
    )
}

import { useCallback, useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Form, Input, Modal, Select, Space, TableProps, Typography } from 'antd'
import { EyeOutlined, HistoryOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { apiAddListCustomerResource, apiGetListCompanies, apiGetListCustomerResource, apiGetListUsers } from '../../api'
import FormSearch from '../../components/form'
import { Link } from 'react-router-dom'
import { CUSTOMER, DETAIL, KIT_SIM } from '../../assets/constants/route_path'
import { toastSuccess } from '../../utils/toast'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { IUser } from '../../models/user.model'
import { ICompany } from '../../models/company.modal'
import { formatDate } from '../../utils/entity-utils'
import { authSelector } from '../../redux/slices/authSlice'
import { useSelector } from 'react-redux'
import { typeCustomerOptions } from '../../assets/constants/default_options'
const { Text } = Typography

export default function Customer() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [userOptions, setUserOptions] = useState<{ label: string, value: Number }[]>([])
    const [customers, setCustomers] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        cp_code: '',
        owner_by: '',
        phone_number: '',
    })
    const [modalCreate, setModalCreate] = useState(false)
    const [modalDetail, setModalDetail] = useState<any>({})

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()
    const [formCreate] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'owner_by',
            title: 'Nhân sự đấu nối',
            dataIndex: 'owner_by',
        },
        {
            key: 'cp_code',
            title: 'Mã công ty',
            dataIndex: 'cp_code',
        },
        {
            key: 'phone_number',
            title: 'SĐT Khách hàng',
            dataIndex: 'phone_number',
        },
        {
            key: 'full_name',
            title: 'Tên khách hàng',
            dataIndex: 'full_name',
        },
        {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
        },
        {
            key: 'action',
            title: 'Tác vụ',
            render: data => (
                <Space>
                    <Button onClick={() => setModalDetail({ isOpen: true, data })} style={{ background: '#289e45', color: 'white' }} icon={<EyeOutlined />}>
                        Chi tiết
                    </Button>
                    <Link to={KIT_SIM + CUSTOMER + DETAIL + `?cp_code=${data.cp_code}&phone_number=${data.phone_number}`}>
                        <Button style={{ background: '#7d3aa7', color: 'white' }} icon={<HistoryOutlined />}>
                            Lịch sử
                        </Button>
                    </Link>
                </Space>
            )
        }
    ]

    const fetchCompanies = useCallback(async () => {
        if (companyOptions.length > 0) return
        try {
            const resp = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resp.data?.companies === null) {
                return
            }
            const options = resp.data.companies.map((item: ICompany) => ({
                label: item.name,
                value: item.code,
            }))
            setCompanyOptions(options)
        } catch (error) {
            console.log(error)
        }
    }, [companyOptions.length])

    const fetchUsers = useCallback(async () => {
        if (userOptions.length > 0) return
        try {
            const resp = await apiGetListUsers({ limit: defaultParams.MAX_LIMIT })
            if (resp.data != null) {
                const options = resp.data.users.map((item: IUser) => ({
                    label: item.username,
                    value: item.username,
                }))
                setUserOptions(options)
            } else {
                setUserOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [userOptions.length])

    const fetchData = useCallback(async () => {
        if (!search.cp_code && !search.owner_by && !search.phone_number) {
            setCustomers({})
            return
        }
        try {
            setIsLoading(true)
            const resPackages = await apiGetListCustomerResource({
                page: currentPage,
                size: pageSize,
                ...search,
            })
            if (resPackages.data != null) {
                setCustomers(resPackages.data)
            } else {
                setCustomers({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    useEffect(() => {
        fetchCompanies()
        fetchUsers()
        fetchData()
    }, [fetchCompanies, fetchUsers, fetchData])

    const handleSearch = ({ cp_code, owner_by, phone_number }: any) => {
        if (cp_code || owner_by || phone_number) {
            setSearch({ cp_code, owner_by, phone_number })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            cp_code: '',
            owner_by: '',
            phone_number: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleCreate = async (values: any) => {
        try {
            setIsLoading(true)
            const resp = await apiAddListCustomerResource({
                customers: [values]
            })
            if (resp.status === 200) {
                fetchData()
                toastSuccess('Thêm khách hàng thành công!')
                setModalCreate(false)
                formCreate.resetFields()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='owner_by' label='Nhân sự đấu nối'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn nhân sự"
                        options={userOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='cp_code' label='Công ty'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn công ty"
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='phone_number' label='SĐT Khách hàng'>
                    <Input />
                </Form.Item>
            </FormSearch>
            {account.canAddListCustomerResource && (
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Button onClick={() => setModalCreate(true)} type='primary'>
                        Thêm khách hàng
                    </Button>
                </div>
            )}
            <CustomTable
                namePage='khách hàng'
                columns={columns}
                dataSource={customers.customers}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={customers.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                title='Thêm khách hàng mới'
                open={modalCreate}
                onCancel={() => setModalCreate(false)}
                footer={[
                    <Button htmlType='submit' form='form-create' type="primary">Thêm</Button>
                ]}
            >
                <Divider />
                <Form form={formCreate} onFinish={handleCreate} name='form-create' labelCol={{ span: 6 }} labelAlign="left" autoComplete='off'>
                    <Form.Item name='cp_code' label='Công ty'
                        rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='phone_number' label='SĐT Khách hàng'
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='full_name' label='Tên khách hàng'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='email' label='Email'>
                        <Input type='email' />
                    </Form.Item>
                    <Form.Item name='address' label='Địa chỉ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='nation' label='Quốc gia'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='date_of_birth' label='Ngày sinh'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='number_identity' label='Số giấy tờ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='type_identity' label='Loại giấy tờ'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại giấy tờ"
                            options={typeCustomerOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='expire_date' label='Ngày hết hạn'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='note' label='Ghi chú'>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title='Thông tin khách hàng'
                open={modalDetail.isOpen}
                onCancel={() => setModalDetail({
                    isOpen: false,
                    data: null,
                })}
                footer={[]}
            >
                <Divider />
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>Mã công ty: </Text>{modalDetail.data?.cp_code}</p>
                        <p><Text strong>SĐT Khách hàng: </Text>{modalDetail.data?.phone_number}</p>
                        <p><Text strong>Tên khách hàng: </Text>{modalDetail.data?.full_name}</p>
                        <p><Text strong>Địa chỉ: </Text>{modalDetail.data?.address}</p>
                        <p><Text strong>Số giấy tờ: </Text>{modalDetail.data?.number_identity}</p>
                        <p><Text strong>Ngày hết hạn: </Text>{modalDetail.data?.expire_date && formatDate(modalDetail.data?.expire_date)}</p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>Nhân sự đấu nối: </Text>{modalDetail.data?.owner_by}</p>
                        <p><Text strong>Email: </Text>{modalDetail.data?.email}</p>
                        <p><Text strong>Ngày sinh: </Text>{modalDetail.data?.date_of_birth && formatDate(modalDetail.data?.date_of_birth)}</p>
                        <p><Text strong>Quốc gia: </Text>{modalDetail.data?.nation}</p>
                        <p><Text strong>Loại giấy tờ: </Text>{modalDetail.data?.type_identity}</p>
                        <p><Text strong>Ngày tạo: </Text>{formatDate(modalDetail.data?.CreatedAt)}</p>
                    </div>
                </div>
                <Text strong>Ghi chú: </Text>{modalDetail.data?.note}
            </Modal>
        </div>
    )
}

import { useCallback, useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Form, Input, Modal, Select, Space, TableProps, Typography } from 'antd'
import { SearchOutlined, UndoOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { apiAddListCustomerResource, apiAddListPhoneAgencies, apiGetListCompanies, apiGetListCustomerResource, apiGetListPhoneAgencies, apiGetListTransactionConnectionExpire, apiUpdateListCustomerResource } from '../../api'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { ICompany } from '../../models/company.modal'
import { renderDateByUnix, renderType } from './method'
import { formatDate } from '../../utils/entity-utils'
import { typeCustomerOptions, typeNoteCustomerOptions } from '../../assets/constants/default_options'
import dayjs from 'dayjs'
import { toastSuccess } from '../../utils/toast'
import { authSelector } from '../../redux/slices/authSlice'
import { useSelector } from 'react-redux'
import { formatCurrency } from '../../utils/string-utils'
const { Text } = Typography

const initCreateAgency = {
    isOpen: false,
    cpCode: '',
    phoneAgency: '',
}

export default function Telesale() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [transactionConnection, setTransactionConnection] = useState<any>({})
    const [customers, setCustomers] = useState<any>([])
    const [agencies, setAgencies] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState<any>({})
    const [modalDetail, setModalDetail] = useState<any>({})
    const [modalUpdate, setModalUpdate] = useState(false)
    const [modalCreateAgency, setModalCreateAgency] = useState(initCreateAgency)
    const [modalCreateCustomer, setModalCreateCustomer] = useState(false)
    const { setIsLoading } = useLoading()
    const [formSearch] = Form.useForm()
    const [formUpdate] = Form.useForm()
    const [formCreateCustomer] = Form.useForm()
    const authState = useSelector(authSelector)
    const { account } = authState

    const fetchCustomer = async ({ cpCode, phoneNumber }: any) => {
        const data = customers.find((cus: any) => cus.cp_code === cpCode && cus.phone_number === phoneNumber)
        formUpdate.setFieldValue('cp_code', data.cp_code)
        formUpdate.setFieldValue('phone_number', data.phone_number)
        formUpdate.setFieldValue('full_name', data.full_name)
        formUpdate.setFieldValue('email', data.email)
        formUpdate.setFieldValue('address', data.address)
        formUpdate.setFieldValue('nation', data.nation)
        formUpdate.setFieldValue('date_of_birth', data.date_of_birth && dayjs(data.date_of_birth))
        formUpdate.setFieldValue('number_identity', data.number_identity)
        formUpdate.setFieldValue('type_identity', data.type_identity)
        formUpdate.setFieldValue('expire_date', data.expire_date && dayjs(data.expire_date))
        formUpdate.setFieldValue('note', data.note)
        setModalDetail({ isOpen: true, data })
    }

    const transactionExpireColumns: TableProps<any>['columns'] = [
        {
            key: 'phoneAgency',
            title: 'SĐT báo KH',
            dataIndex: 'phoneAgency',
            render: phoneAgency => phoneAgency ? agencies.find((agen: any) => agen.id === phoneAgency)?.phoneAgency : '',
        },
        {
            key: 'phoneSim',
            title: 'Msisdn',
            dataIndex: 'phoneSim',
        },
        {
            key: 'serialSim',
            title: 'Serial Sim',
            dataIndex: 'serialSim',
        },
        {
            key: 'phoneCustomer',
            title: 'SĐT Khách hàng',
            dataIndex: 'phoneCustomer',
        },
        {
            key: 'dataPack',
            title: 'Gói cước',
            dataIndex: 'dataPack',
        },
        {
            key: 'price',
            title: 'Giá',
            dataIndex: 'price',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'startDate',
            title: 'Ngày đấu nối',
            dataIndex: 'startDate',
            render: startDate => renderDateByUnix(startDate),
        },
        {
            key: 'endDate',
            title: 'Ngày hết hạn',
            dataIndex: 'endDate',
            render: endDate => renderDateByUnix(endDate),
        },
        {
            key: 'type',
            title: 'Loại gói cước',
            dataIndex: 'type',
            render: type => renderType(type),
        },
        {
            key: 'note',
            title: 'Ghi chú',
            dataIndex: 'phoneCustomer',
            render: phoneCustomer => phoneCustomer ? customers.find((cus: any) => cus.phone_number === phoneCustomer)?.note : '',
        },
        {
            key: 'action',
            title: 'Tác vụ',
            render: data => renderAction(data)
        }
    ]

    const renderAction = (data: any) => {
        const customer = customers.find((cus: any) => cus.phone_number === data.phoneCustomer)
        if (customer) {
            return (
                <Space>
                    <Button onClick={() => fetchCustomer({ cpCode: data.cpCode, phoneNumber: data.phoneCustomer })}
                        style={{ background: '#289e45', color: 'white' }} icon={<EyeOutlined />}>
                        Chi tiết
                    </Button>
                </Space>
            )
        }
        const agency = agencies.find((agen: any) => agen.phoneAgency === `0${data.phoneCustomer}`)
        if (agency) {
            return (
                <Button disabled>
                    Đại lý
                </Button>
            )
        }
        return (
            <Space>
                <Button onClick={() => {
                    formCreateCustomer.setFieldValue('cp_code', data.cpCode)
                    formCreateCustomer.setFieldValue('phone_number', data.phoneCustomer)
                    setModalCreateCustomer(true)
                }}
                    style={{ background: '#a49c22', color: 'white' }} icon={<PlusOutlined />}>
                    Khách hàng
                </Button>
                <Button onClick={() => setModalCreateAgency({
                    isOpen: true,
                    cpCode: data.cpCode,
                    phoneAgency: data.phoneCustomer
                })} style={{ background: '#285a9e', color: 'white' }} icon={<PlusOutlined />}>
                    Đại lý
                </Button>
            </Space>
        )
    }

    const fetchCustomers = useCallback(async (trans: any) => {
        const phoneCustomers = [] as string[]
        trans.map((tran: any) => {
            if (tran.phoneCustomer && !phoneCustomers.includes(tran.phoneCustomer)) {
                phoneCustomers.push(`'${tran.phoneCustomer}'`)
            }
            return tran
        })
        if (phoneCustomers.length > 0) {
            const respCustomer = await apiGetListCustomerResource({
                page: 1,
                size: phoneCustomers.length,
                condition: `cp_code = '${search.cpCode}' AND phone_number IN (${phoneCustomers.toString()})`
            })
            setCustomers(respCustomer.data.customers)
        }
    }, [search.cpCode])

    const fetchAgencies = useCallback(async (trans: any) => {
        const phoneAgencies = [] as string[]
        const idAgencies = [] as string[]
        trans.map((tran: any) => {
            if (tran.phoneCustomer && !phoneAgencies.includes(tran.phoneCustomer)) {
                phoneAgencies.push(`'0${tran.phoneCustomer}'`)
            }
            if (tran.phoneAgency && !idAgencies.includes(tran.phoneAgency)) {
                idAgencies.push(`'${tran.phoneAgency}'`)
            }
            return tran
        })
        const totalPhone = phoneAgencies.length + idAgencies.length
        if (totalPhone > 0) {
            const respAgency = await apiGetListPhoneAgencies({
                page: 1,
                size: totalPhone,
                condition: `
                    "cpCode" = '${search.cpCode}' 
                    AND ("phoneAgency" IN (${phoneAgencies.length > 0 ? phoneAgencies.toString() : "''"}) 
                    OR id IN (${idAgencies.length > 0 ? idAgencies.toString() : "''"}))
                `
            })
            setAgencies(respAgency.data.phoneAgencies)
        }
    }, [search.cpCode])

    const fetchTransactionConnection = useCallback(async () => {
        if (!search.cpCode && !search.day) {
            setTransactionConnection({})
            return
        }
        try {
            setIsLoading(true)
            const resp = await apiGetListTransactionConnectionExpire({
                page: currentPage,
                size: pageSize,
                ...search,
            })
            if (resp.status === 200) {
                fetchCustomers(resp.data?.transactions)
                fetchAgencies(resp.data?.transactions)
                setTransactionConnection(resp.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, fetchCustomers, fetchAgencies, currentPage, pageSize, search])

    const fetchCompanies = useCallback(async () => {
        try {
            const resp = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resp.data != null) {
                const options = resp.data.companies.map((item: ICompany) => ({
                    label: item.name,
                    value: item.code,
                }))
                setCompanyOptions(options)
            } else {
                setCompanyOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchTransactionConnection()
        fetchCompanies()
    }, [fetchTransactionConnection, fetchCompanies])

    const handleSearch = ({ cpCode, day, date }: any) => {
        if (!cpCode && !day) return
        let startDate, endDate
        if (date) {
            startDate = date[0] ? date[0]?.format('YYYY-MM-DD') : null
            endDate = date[1] ? date[1]?.format('YYYY-MM-DD') : null
        }
        setSearch({ cpCode, day, startDate, endDate })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({})
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleUpdate = async (values: any) => {
        try {
            setIsLoading(true)
            const dataUpdate = {
                ID: modalDetail.data.ID,
                customer_id: modalDetail.data.customer_id,
                ...values,
            }
            const resp = await apiUpdateListCustomerResource({
                customers: [dataUpdate]
            })
            if (resp.status === 200) {
                toastSuccess('Cập nhật thông tin thành công!')
                const updateCustomer = customers.map(((cus: any) => {
                    if (cus.cp_code === modalDetail.data.cp_code && cus.phone_number === modalDetail.data.phone_number) {
                        cus = dataUpdate
                        setModalDetail({ isOpen: true, data: cus })
                    }
                    return cus
                }))
                setCustomers(updateCustomer)
                setModalUpdate(false)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleCreateAgency = async () => {
        try {
            setIsLoading(true)
            const resp = await apiAddListPhoneAgencies({
                phoneAgencies: [{
                    cpCode: modalCreateAgency.cpCode,
                    phoneAgency: modalCreateAgency.phoneAgency,
                }]
            })
            if (resp.status === 200) {
                toastSuccess('Thêm đại lý thành công!')
                fetchAgencies(transactionConnection.transactions)
                setModalCreateAgency(initCreateAgency)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleCreateCustomer = async (values: any) => {
        try {
            setIsLoading(true)
            const resp = await apiAddListCustomerResource({
                customers: [values]
            })
            if (resp.status === 200) {
                toastSuccess('Thêm khách hàng thành công!')
                fetchCustomers(transactionConnection.transactions)
                setModalCreateCustomer(false)
                formCreateCustomer.resetFields()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <Form form={formSearch} onFinish={handleSearch} autoComplete='off'>
                <div style={{ display: 'flex', alignItems: 'start', flexWrap: 'wrap', gap: 20 }}>
                    <Form.Item name='cpCode' label='Công ty'
                        rules={[{ required: true, message: 'Vui lòng chọn công ty' }]}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='day' label='Số ngày còn lại'>
                        <Input />
                    </Form.Item>
                    <Form.Item name="date" label="Ngày hết hạn">
                        <DatePicker.RangePicker
                            format="YYYY-MM-DD"
                            picker="date" />
                    </Form.Item>
                    <Space style={{ marginBottom: 24 }}>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </div>
            </Form>
            <CustomTable
                namePage='giao dịch'
                columns={transactionExpireColumns}
                dataSource={transactionConnection.transactions}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={transactionConnection.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                title='Thông tin khách hàng'
                open={modalDetail.isOpen}
                onCancel={() => {
                    setModalDetail({
                        isOpen: false,
                        data: null,
                    })
                }}
                footer={[
                    account.canUpdateListCustomerResource && (
                        <Button onClick={() => setModalUpdate(true)} type="primary">Cập nhật</Button>
                    )
                ]}
            >
                <Divider />
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>Mã công ty: </Text>{modalDetail.data?.cp_code}</p>
                        <p><Text strong>SĐT Khách hàng: </Text>{modalDetail.data?.phone_number}</p>
                        <p><Text strong>Tên khách hàng: </Text>{modalDetail.data?.full_name}</p>
                        <p><Text strong>Địa chỉ: </Text>{modalDetail.data?.address}</p>
                        <p><Text strong>Số giấy tờ: </Text>{modalDetail.data?.number_identity}</p>
                        <p><Text strong>Ngày hết hạn: </Text>{modalDetail.data?.expire_date && formatDate(modalDetail.data?.expire_date)}</p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>Nhân sự đấu nối: </Text>{modalDetail.data?.owner_by}</p>
                        <p><Text strong>Email: </Text>{modalDetail.data?.email}</p>
                        <p><Text strong>Ngày sinh: </Text>{modalDetail.data?.date_of_birth && formatDate(modalDetail.data?.date_of_birth)}</p>
                        <p><Text strong>Quốc gia: </Text>{modalDetail.data?.nation}</p>
                        <p><Text strong>Loại giấy tờ: </Text>{modalDetail.data?.type_identity}</p>
                        <p><Text strong>Ngày tạo: </Text>{formatDate(modalDetail.data?.CreatedAt)}</p>
                    </div>
                </div>
                <Text strong>Ghi chú: </Text>{modalDetail.data?.note}
            </Modal>
            <Modal
                title='Cập nhật thông tin khách hàng'
                open={modalUpdate}
                onCancel={() => setModalUpdate(false)}
                footer={[
                    <Button htmlType='submit' form='form-update' type="primary">Cập nhật</Button>
                ]}
            >
                <Divider />
                <Form form={formUpdate} onFinish={handleUpdate} name='form-update' labelCol={{ span: 6 }} labelAlign="left" autoComplete="off">
                    <Form.Item name='cp_code' label='Công ty'
                        rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
                        <Select
                            disabled
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='phone_number' label='SĐT Khách hàng'
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name='full_name' label='Tên khách hàng'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='email' label='Email'>
                        <Input type='email' />
                    </Form.Item>
                    <Form.Item name='address' label='Địa chỉ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='nation' label='Quốc gia'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='date_of_birth' label='Ngày sinh'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='number_identity' label='Số giấy tờ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='type_identity' label='Loại giấy tờ'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại giấy tờ"
                            options={typeCustomerOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='expire_date' label='Ngày hết hạn'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='note' label='Ghi chú'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại ghi chú"
                            options={typeNoteCustomerOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='note'>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title='Thêm đại lý mới'
                open={modalCreateAgency.isOpen}
                onCancel={() => setModalCreateAgency(initCreateAgency)}
                footer={[
                    <Button onClick={handleCreateAgency} type="primary">Thêm</Button>
                ]}
            >
                <Divider />
                Thêm số đại lý <b>{modalCreateAgency.phoneAgency}</b> cho đối tác <b>{modalCreateAgency.cpCode}</b>?
            </Modal>
            <Modal
                title='Thêm khách hàng mới'
                open={modalCreateCustomer}
                onCancel={() => setModalCreateCustomer(false)}
                footer={[
                    <Button htmlType='submit' form='form-create' type="primary">Thêm</Button>
                ]}
            >
                <Divider />
                <Form form={formCreateCustomer} onFinish={handleCreateCustomer} name='form-create' labelCol={{ span: 6 }} labelAlign="left" autoComplete='off'>
                    <Form.Item name='cp_code' label='Công ty'
                        rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
                        <Select
                            disabled
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='phone_number' label='SĐT Khách hàng'
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name='full_name' label='Tên khách hàng'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='email' label='Email'>
                        <Input type='email' />
                    </Form.Item>
                    <Form.Item name='address' label='Địa chỉ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='nation' label='Quốc gia'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='date_of_birth' label='Ngày sinh'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='number_identity' label='Số giấy tờ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='type_identity' label='Loại giấy tờ'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại giấy tờ"
                            options={typeCustomerOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='expire_date' label='Ngày hết hạn'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='note' label='Ghi chú'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại ghi chú"
                            options={typeNoteCustomerOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='note'>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

import ButtonBack from "../../components/button/ButtonBack"
import CustomTable from "../../components/table"
import { Button, Card, Divider, Form, Input, Modal, Select, Typography } from "antd"
import { useCallback, useEffect, useState } from "react"
import defaultParams from "../../assets/constants/default_params"
import FormSearch from "../../components/form"
import { filterOptionByLabel } from "../../utils/filter-utils"
import { mbhStatusOptions, transactionConnectionTypeOptions } from "../../assets/constants/default_options"
import { apiGetListCompanies, apiGetListTransactionConnection, apiGetListPhoneAgencies, apiUpdateListPhoneAgencies } from "../../api"
import { ICompany } from "../../models/company.modal"
import { toastSuccess } from "../../utils/toast"
import { useSearchParams } from "react-router-dom"
import { transactionConnectionColumns } from "../kit-sim/method"
import { useLoading } from "../../context/LoadingContext"
import { authSelector } from "../../redux/slices/authSlice"
import { useSelector } from "react-redux"
const { Text } = Typography

export default function AgencyDetail() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [modalUpdate, setModalUpdate] = useState(false)
    const [searchParams] = useSearchParams()
    const cpCode = searchParams.get('cpCode')
    const phoneAgency = searchParams.get('phoneAgency')
    const [agency, setAgency] = useState<any>({})
    const [transactionConnection, setTransactionConnection] = useState<any>({})
    const [search, setSearch] = useState({
        type: '',
        status: '',
        serialSim: '',
        phoneCustomer: '',
    })

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()
    const [formUpdate] = Form.useForm()

    const fetchAgency = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetListPhoneAgencies({ cpCode, phoneAgency })
            if (resp.data.phoneAgencies) {
                setAgency(resp.data.phoneAgencies[0])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, cpCode, phoneAgency])

    const fetchTransactionConnection = useCallback(async () => {
        if (!agency?.id) return
        try {
            const resp = await apiGetListTransactionConnection({ phoneAgency: agency?.id, ...search, })
            if (resp.data.transactions) {
                setTransactionConnection(resp.data)
            }
        } catch (error) {
            console.log(error)
        }
    }, [agency?.id, search])

    const fetchCompanies = useCallback(async () => {
        try {
            const resp = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resp.data != null) {
                const options = resp.data.companies.map((item: ICompany) => ({
                    label: item.name,
                    value: item.code,
                }))
                setCompanyOptions(options)
            } else {
                setCompanyOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchAgency()
        fetchTransactionConnection()
        fetchCompanies()
    }, [fetchAgency, fetchTransactionConnection, fetchCompanies])

    const handleSearch = ({ status, serialSim, phoneCustomer, type }: any) => {
        if (status || serialSim || phoneCustomer || type) {
            setSearch({ status, serialSim, phoneCustomer, type })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            type: '',
            status: '',
            serialSim: '',
            phoneCustomer: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleUpdate = async (values: any) => {
        try {
            setIsLoading(true)
            const resp = await apiUpdateListPhoneAgencies({
                phoneAgencies: [{
                    id: agency?.id,
                    cpCode: values.cpCode,
                    phoneAgency: values.phoneAgency,
                }]
            })
            if (resp.status === 200) {
                fetchAgency()
                toastSuccess('Cập nhật thông tin thành công!')
                setModalUpdate(false)
                formUpdate.resetFields()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <Card
                title={<h3>Thông tin đại lý</h3>}
                extra={(account.canUpdateListPhoneAgencies &&
                    <Button onClick={() => setModalUpdate(true)} type="primary">Cập nhật</Button>
                )}
            >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>Mã công ty: </Text>{agency?.cpCode}</p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>SĐT Đại lý: </Text>{agency?.phoneAgency}</p>
                    </div>
                </div>
            </Card>
            <Divider />
            <h2>Lịch sử giao dịch</h2>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='type' label='Loại giao dịch'>
                    <Select
                        style={{ width: 170 }}
                        showSearch
                        placeholder="Chọn loại giao dịch"
                        options={transactionConnectionTypeOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='status' label='Trạng thái'>
                    <Select
                        style={{ width: 170 }}
                        showSearch
                        placeholder="Chọn trạng thái"
                        options={mbhStatusOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='serialSim' label='SerialSim'>
                    <Input />
                </Form.Item>
                <Form.Item name='phoneCustomer' label='SĐT Khách hàng'>
                    <Input />
                </Form.Item>
            </FormSearch>
            <CustomTable
                namePage='giao dịch'
                columns={transactionConnectionColumns}
                dataSource={transactionConnection.transactions}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={transactionConnection.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                title='Cập nhật thông tin đại lý'
                open={modalUpdate}
                onCancel={() => setModalUpdate(false)}
                footer={[
                    <Button htmlType='submit' form='form-update' type="primary">Cập nhật</Button>
                ]}
            >
                <Divider />
                <Form form={formUpdate} onFinish={handleUpdate} name='form-update' labelCol={{ span: 6 }} labelAlign="left" autoComplete="off">
                    <Form.Item name='cpCode' label='Công ty' initialValue={agency?.cpCode}
                        rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
                        <Select
                            disabled
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='phoneAgency' label='SĐT Đại lý' initialValue={agency?.phoneAgency}
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}>
                        <Input disabled />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

import ButtonBack from "../../components/button/ButtonBack"
import CustomTable from "../../components/table"
import { Button, Card, DatePicker, Divider, Form, Input, Modal, Select, Typography } from "antd"
import { useCallback, useEffect, useState } from "react"
import defaultParams from "../../assets/constants/default_params"
import FormSearch from "../../components/form"
import { filterOptionByLabel } from "../../utils/filter-utils"
import { mbhStatusOptions, transactionConnectionTypeOptions, typeCustomerOptions } from "../../assets/constants/default_options"
import { apiGetListCompanies, apiGetListCustomerResource, apiGetListTransactionConnection, apiUpdateListCustomerResource } from "../../api"
import { ICompany } from "../../models/company.modal"
import { toastSuccess } from "../../utils/toast"
import { useSearchParams } from "react-router-dom"
import { transactionConnectionColumns } from "../kit-sim/method"
import { useLoading } from "../../context/LoadingContext"
import { authSelector } from "../../redux/slices/authSlice"
import { useSelector } from "react-redux"
import { formatDate } from "../../utils/entity-utils"
import dayjs from 'dayjs'
const { Text } = Typography

export default function CustomerDetail() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [modalUpdate, setModalUpdate] = useState(false)
    const [searchParams] = useSearchParams()
    const cpCode = searchParams.get('cp_code')
    const phoneNumber = searchParams.get('phone_number')
    const [customer, setCustomer] = useState<any>({})
    const [transactionConnection, setTransactionConnection] = useState<any>({})
    const [search, setSearch] = useState({
        type: '',
        status: '',
        serialSim: '',
    })

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()
    const [formUpdate] = Form.useForm()

    const fetchCustomer = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetListCustomerResource({ cp_code: cpCode, phone_number: phoneNumber })
            if (resp.data.customers) {
                setCustomer(resp.data.customers[0])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, cpCode, phoneNumber])

    const fetchTransactionConnection = useCallback(async () => {
        try {
            const resp = await apiGetListTransactionConnection({
                cpCode: cpCode,
                phoneCustomer: phoneNumber,
                ...search,
            })
            if (resp.data.transactions) {
                setTransactionConnection(resp.data)
            }
        } catch (error) {
            console.log(error)
        }
    }, [cpCode, phoneNumber, search])

    const fetchCompanies = useCallback(async () => {
        try {
            const resPackages = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resPackages.data != null) {
                const options = resPackages.data.companies.map((item: ICompany) => ({
                    label: item.name,
                    value: item.code,
                }))
                setCompanyOptions(options)
            } else {
                setCompanyOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchCustomer()
        fetchTransactionConnection()
        fetchCompanies()
    }, [fetchCustomer, fetchTransactionConnection, fetchCompanies])

    const handleSearch = ({ status, serialSim, type }: any) => {
        if (status || serialSim || type) {
            setSearch({ status, serialSim, type })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            type: '',
            status: '',
            serialSim: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleUpdate = async (values: any) => {
        try {
            setIsLoading(true)
            const resp = await apiUpdateListCustomerResource({
                customers: [{
                    ID: customer.ID,
                    customer_id: customer.customer_id,
                    ...values,
                }]
            })
            if (resp.status === 200) {
                fetchCustomer()
                toastSuccess('Cập nhật thông tin thành công!')
                setModalUpdate(false)
                formUpdate.resetFields()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <Card
                title={<h3>Thông tin khách hàng</h3>}
                extra={account.canUpdateListCustomerResource && (
                    <Button onClick={() => setModalUpdate(true)} type="primary">Cập nhật</Button>
                )}
            >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>Mã công ty: </Text>{customer.cp_code}</p>
                        <p><Text strong>SĐT Khách hàng: </Text>{customer.phone_number}</p>
                        <p><Text strong>Địa chỉ: </Text>{customer.address}</p>
                        <p><Text strong>Số giấy tờ: </Text>{customer.number_identity}</p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>Nhân sự đấu nối: </Text>{customer.owner_by}</p>
                        <p><Text strong>Tên khách hàng: </Text>{customer.full_name}</p>
                        <p><Text strong>Quốc gia: </Text>{customer.nation}</p>
                        <p><Text strong>Loại giấy tờ: </Text>{customer.type_identity}</p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>Ngày tạo: </Text>{formatDate(customer.CreatedAt)}</p>
                        <p><Text strong>Email: </Text>{customer.email}</p>
                        <p><Text strong>Ngày sinh: </Text>{customer.date_of_birth && formatDate(customer.date_of_birth)}</p>
                        <p><Text strong>Ngày hết hạn: </Text>{customer.expire_date && formatDate(customer.expire_date)}</p>
                    </div>
                </div>
                <Text strong>Ghi chú: </Text>{customer.note}
            </Card>
            <Divider />
            <h2>Lịch sử giao dịch</h2>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='type' label='Loại giao dịch'>
                    <Select
                        style={{ width: 170 }}
                        showSearch
                        placeholder="Chọn loại giao dịch"
                        options={transactionConnectionTypeOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='status' label='Trạng thái'>
                    <Select
                        style={{ width: 170 }}
                        showSearch
                        placeholder="Chọn trạng thái"
                        options={mbhStatusOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='serialSim' label='SerialSim'>
                    <Input />
                </Form.Item>
            </FormSearch>
            <CustomTable
                namePage='giao dịch'
                columns={transactionConnectionColumns}
                dataSource={transactionConnection.transactions}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={transactionConnection.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                title='Cập nhật thông tin khách hàng'
                open={modalUpdate}
                onCancel={() => setModalUpdate(false)}
                footer={[
                    <Button htmlType='submit' form='form-update' type="primary">Cập nhật</Button>
                ]}
            >
                <Divider />
                <Form form={formUpdate} onFinish={handleUpdate} name='form-update' labelCol={{ span: 6 }} labelAlign="left" autoComplete="off">
                    <Form.Item name='cp_code' label='Công ty' initialValue={customer.cp_code}
                        rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
                        <Select
                            disabled
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='phone_number' label='SĐT Khách hàng' initialValue={customer.phone_number}
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name='full_name' label='Tên khách hàng' initialValue={customer.full_name}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='email' label='Email' initialValue={customer.email}>
                        <Input type='email' />
                    </Form.Item>
                    <Form.Item name='address' label='Địa chỉ' initialValue={customer.address}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='nation' label='Quốc gia' initialValue={customer.nation}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='date_of_birth' label='Ngày sinh' initialValue={customer.date_of_birth && dayjs(customer.date_of_birth)}>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='number_identity' label='Số giấy tờ' initialValue={customer.number_identity}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='type_identity' label='Loại giấy tờ' initialValue={customer.type_identity}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại giấy tờ"
                            options={typeCustomerOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='expire_date' label='Ngày hết hạn' initialValue={customer.expire_date && dayjs(customer.expire_date)}>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='note' label='Ghi chú' initialValue={customer.note}>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Form, Input, InputNumber, Modal, TableProps } from 'antd'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { apiDeletePackage, apiGetListPackages } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import { IPackage, IPackages } from '../../models/package.modal'
import { Link } from 'react-router-dom';
import { PACKAGE, CREATE, OVERVIEW } from '../../assets/constants/route_path'
import { toastError, toastSuccess } from '../../utils/toast'
import { formatCurrency } from '../../utils/string-utils'
import FormSearch from '../../components/form'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
import ViewOverview from './ViewOverview'
import Action from './Action'
import { formatDate } from '../../utils/entity-utils'

export default function PackageOverview() {
    const [packages, setPackages] = useState<IPackages>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        name: '',
        code: '',
        price: 0,
        numberDay: 0,
    })
    const [modalDelete, setModalDelete] = useState<{ data: IPackage | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: 'STT',
            render: (value, record, index) => ++index + (pageSize * (currentPage - 1)),
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
        },
        {
            key: 'code',
            title: 'Mã gói cước',
            dataIndex: 'code',
        },
        {
            key: 'numberDay',
            title: 'Hạn sử dụng',
            dataIndex: 'numberDay',
            render: (numberDay) => `${numberDay} ngày`
        },
        {
            key: 'price',
            title: 'Giá gốc',
            dataIndex: 'price',
            render: (price) => formatCurrency(price || 0),
        },
        {
            key: 'modify',
            title: 'Cập nhật',
            dataIndex: 'modify',
            render: (modify) => formatDate(modify),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: IPackage) => (
                <Action
                    canDelete={account.canDeletePackage}
                    hanldeDelete={() => setModalDelete({ data, isOpen: true })}
                    handleView={() => setModalView({ data, isOpen: true })}
                />
            ),
        },
    ]

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resPackages = await apiGetListPackages({
                limit: pageSize,
                offset: pageSize * (currentPage - 1),
                name: search.name,
                code: search.code,
                price: search.price,
                numberDay: search.numberDay,
            })
            if (resPackages.data != null) {
                setPackages(resPackages.data)
            } else {
                setPackages({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSearch = ({ name, code, price, numberDay }: any) => {
        if (name || code || price || numberDay) {
            setSearch({ name, code, price, numberDay })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            name: '',
            code: '',
            price: 0,
            numberDay: 0,
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleDelete = async () => {
        try {
            setIsLoading(true)
            const resp = await apiDeletePackage(modalDelete.data?.id)
            if (resp.status === 200) {
                setModalDelete({ data: null, isOpen: false })
                toastSuccess('Xoá gói cước thành công!')
                fetchData()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='name' label='Tên gói cước'>
                    <Input />
                </Form.Item>
                <Form.Item name='code' label='Mã gói cước'>
                    <Input />
                </Form.Item>
                <Form.Item name='price' label='Giá gốc'>
                    <InputNumber formatter={value => formatCurrency(Number(value))} style={{ minWidth: 170 }} />
                </Form.Item>
                <Form.Item name='numberDay' label='Hạn sử dụng'>
                    <InputNumber style={{ minWidth: 170 }} />
                </Form.Item>
            </FormSearch>
            {account.canAddListPackages && (
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Link to={PACKAGE + OVERVIEW + CREATE}>
                        <Button type='primary'>Thêm gói cước</Button>
                    </Link>
                </div>
            )}
            <CustomTable
                namePage='gói cước'
                columns={columns}
                dataSource={packages.packages}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={packages.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Xoá gói cước' open={modalDelete.isOpen}
                onCancel={() => setModalDelete({ data: null, isOpen: false })}
                footer={[
                    <Button onClick={handleDelete} type='primary' danger>
                        Xoá
                    </Button>,
                ]}>
                <Divider />
                <div>Bạn có chắc muốn xoá gói cước <b>{modalDelete.data?.name}</b>?</div>
            </Modal>
            <ViewOverview
                modalView={modalView}
                setModalView={setModalView}
            />
        </div>
    )
}

import { Divider, Modal } from 'antd'
import { formatCurrency } from '../../utils/string-utils'
import { formatDate } from '../../utils/entity-utils'

type FieldModalView = {
    modalView: { data: any | null, isOpen: boolean }
    setModalView: Function
}

export const renderTypePackages = (type: string) => {
    switch (type) {
        case 'EXPIRE_DAY':
            return 'Hết hạn theo số ngày'
        case 'EXPIRE_END_MONTH':
            return 'Hết hạn vào ngày cuối tháng'
        case 'EXPIRE_MONTH':
            return 'Hết hạn vào ngày đó của tháng sau'
        default:
            return 'Không xác định'
    }
}

export default function ViewOverview({ modalView, setModalView }: FieldModalView) {
    return (
        <Modal
            title='Chi tiết gói cước'
            open={modalView.isOpen}
            footer={[]}
            onCancel={() => setModalView({ data: null, isOpen: false })}>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <p><b>Tên gói: </b>{modalView.data?.name}</p>
                    <p><b>Mã gói: </b>{modalView.data?.code}</p>
                    <p><b>Chu kỳ tính cước: </b>{renderTypePackages(modalView.data?.type)}</p>
                </div>
                <div>
                    <p><b>Giá gốc: </b>{formatCurrency(modalView.data?.price)}</p>
                    <p><b>Cập nhật: </b>{formatDate(modalView.data?.modify)}</p>
                </div>
            </div>
            <div><b>Mô tả: </b>{modalView.data?.description}</div>
            <p><b>Ghi chú: </b>{modalView.data?.note}</p>
        </Modal>
    )
}

import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Form, Input, Modal, Select, TableProps } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { apiAddListPhoneAgencies, apiGetListCompanies, apiGetListPhoneAgencies } from '../../api'
import FormSearch from '../../components/form'
import { Link } from 'react-router-dom'
import { AGENCY, DETAIL, KIT_SIM } from '../../assets/constants/route_path'
import { toastSuccess } from '../../utils/toast'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { ICompany } from '../../models/company.modal'
import { formatDate } from '../../utils/entity-utils'
import { authSelector } from '../../redux/slices/authSlice'
import { useSelector } from 'react-redux'

export default function Agency() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [agencies, setAgencies] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        cpCode: '',
        phoneAgency: '',
    })
    const [modalCreate, setModalCreate] = useState(false)

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()
    const [formCreate] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'cpCode',
            title: 'Mã công ty',
            dataIndex: 'cpCode',
        },
        {
            key: 'phoneAgency',
            title: 'SĐT Đại lý',
            dataIndex: 'phoneAgency',
        },
        {
            key: 'createdAt',
            title: 'Ngày tạo',
            dataIndex: 'createdAt',
            render: createdAt => formatDate(createdAt)
        },
        {
            key: 'action',
            title: 'Tác vụ',
            render: data => (
                <Link to={KIT_SIM + AGENCY + DETAIL + `?cpCode=${data.cpCode}&phoneAgency=${data.phoneAgency}`}>
                    <Button style={{ background: '#289e45', color: 'white' }} icon={<EyeOutlined />}>
                        Chi tiết
                    </Button>
                </Link>
            )
        }
    ]

    const fetchCompanies = useCallback(async () => {
        if (companyOptions.length > 0) return
        try {
            const resp = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resp.data?.companies === null) {
                return
            }
            const options = resp.data.companies.map((item: ICompany) => ({
                label: item.name,
                value: item.code,
            }))
            setCompanyOptions(options)
        } catch (error) {
            console.log(error)
        }
    }, [companyOptions.length])

    const fetchData = useCallback(async () => {
        if (!search.cpCode && !search.phoneAgency) {
            setAgencies({})
            return
        }
        try {
            setIsLoading(true)
            const resPackages = await apiGetListPhoneAgencies({
                page: currentPage,
                size: pageSize,
                ...search,
            })
            if (resPackages.data != null) {
                setAgencies(resPackages.data)
            } else {
                setAgencies({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    useEffect(() => {
        fetchCompanies()
        fetchData()
    }, [fetchCompanies, fetchData])

    const handleSearch = ({ cpCode, phoneAgency }: any) => {
        if (cpCode || phoneAgency) {
            setSearch({ cpCode, phoneAgency })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            cpCode: '',
            phoneAgency: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleCreate = async (values: any) => {
        try {
            setIsLoading(true)
            const resp = await apiAddListPhoneAgencies({
                phoneAgencies: [{
                    cpCode: values.cpCode,
                    phoneAgency: values.phoneAgency,
                }]
            })
            if (resp.status === 200) {
                fetchData()
                toastSuccess('Thêm đại lý thành công!')
                setModalCreate(false)
                formCreate.resetFields()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='cpCode' label='Công ty'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn công ty"
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='phoneAgency' label='SĐT Đại lý'>
                    <Input />
                </Form.Item>
            </FormSearch>
            {account.canAddListPhoneAgencies && (
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Button onClick={() => setModalCreate(true)} type='primary'>
                        Thêm đại lý
                    </Button>
                </div>
            )}
            <CustomTable
                namePage='đại lý'
                columns={columns}
                dataSource={agencies.phoneAgencies}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={agencies.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                title='Thêm đại lý mới'
                open={modalCreate}
                onCancel={() => setModalCreate(false)}
                footer={[
                    <Button htmlType='submit' form='form-create' type="primary">Thêm</Button>
                ]}
            >
                <Divider />
                <Form form={formCreate} onFinish={handleCreate} name='form-create' labelCol={{ span: 6 }} labelAlign="left" autoComplete='off'>
                    <Form.Item name='cpCode' label='Công ty'
                        rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='phoneAgency' label='SĐT Đại lý'
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

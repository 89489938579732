import moment from "moment"
import { FORMAT_DATE } from "../../assets/constants/default_params"
import { TableProps, Tag } from "antd"
import { formatCurrency } from "../../utils/string-utils"

export const renderDateByUnix = (dateUnix: number) => {
    return moment.unix(dateUnix).format(FORMAT_DATE)
}

export const renderType = (type: string) => {
    const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
    if (type === 'CONNECTION') return <Tag style={style} color="green">Đấu mới</Tag>
    if (type === 'EXTENSION') return <Tag style={style} color="purple">Gia hạn</Tag>
    return
}

export const renderStatus = (state: string) => {
    const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
    if (state === 'SUCCESS') return <Tag style={style} color="#9b9b05">Thành công</Tag>
    if (state === 'FAIL') return <Tag style={style} color="#c93c3c">Thất bại</Tag>
    if (state === 'ERROR') return <Tag style={style} color="#c93c3c">Lỗi</Tag>
    if (state === 'CREATED') return <Tag style={style} color="#2faac6">Đã tạo</Tag>
    if (state === 'PROCESSING') return <Tag style={style} color="#1d9a46">Đang xử lý</Tag>
    return
}

export const transactionConnectionColumns: TableProps<any>['columns'] = [
    {
        key: 'cpCode',
        title: 'Mã công ty',
        dataIndex: 'cpCode',
    },
    {
        key: 'ownerBy',
        title: 'Nhân sự đấu nối',
        dataIndex: 'ownerBy',
    },
    {
        key: 'phoneSim',
        title: 'Msisdn',
        dataIndex: 'phoneSim',
    },
    {
        key: 'serialSim',
        title: 'Serial Sim',
        dataIndex: 'serialSim',
    },
    {
        key: 'phoneCustomer',
        title: 'SĐT Khách hàng',
        dataIndex: 'phoneCustomer',
    },
    {
        key: 'dataPack',
        title: 'Gói cước',
        dataIndex: 'dataPack',
    },
    {
        key: 'price',
        title: 'Giá',
        dataIndex: 'price',
        render: price => (
            <span style={{ whiteSpace: 'nowrap' }}>
                {formatCurrency(price)}
            </span>
        ),
    },
    {
        key: 'startDate',
        title: 'Ngày đấu nối',
        dataIndex: 'startDate',
        render: startDate => renderDateByUnix(startDate),
    },
    {
        key: 'endDate',
        title: 'Ngày hết hạn',
        dataIndex: 'endDate',
        render: endDate => renderDateByUnix(endDate),
    },
    {
        key: 'type',
        title: 'Loại gói cước',
        dataIndex: 'type',
        render: type => renderType(type),
    },
    {
        key: 'status',
        title: 'Trạng thái',
        dataIndex: 'status',
        render: status => renderStatus(status),
    },
]

export const transactionConnectionResponseColumns: TableProps<any>['columns'] = [
    {
        key: 'cpCode',
        title: 'Mã công ty',
        dataIndex: 'cpCode',
    },
    {
        key: 'phoneAgency',
        title: 'SĐT báo KH',
        dataIndex: 'phoneAgency',
    },
    {
        key: 'phoneSim',
        title: 'Msisdn',
        dataIndex: 'phoneSim',
    },
    {
        key: 'serialSim',
        title: 'Serial Sim',
        dataIndex: 'serialSim',
    },
    {
        key: 'phoneCustomer',
        title: 'SĐT Khách hàng',
        dataIndex: 'phoneCustomer',
    },
    {
        key: 'dataPack',
        title: 'Gói cước',
        dataIndex: 'dataPack',
    },
    {
        key: 'price',
        title: 'Giá',
        dataIndex: 'price',
        render: price => (
            <span style={{ whiteSpace: 'nowrap' }}>
                {formatCurrency(price)}
            </span>
        ),
    },
    {
        key: 'packType',
        title: 'Loại gói cước',
        dataIndex: 'packType',
        render: packType => renderType(packType),
    },
    {
        key: 'message',
        title: 'Kết quả',
        dataIndex: 'message',
    },
]

import { Line } from 'react-chartjs-2'
import { colorPalette } from '../../utils/color-utils'

export default function ChartExtendByMonth({ extended, expired, filtered }: any) {
    const percentExpired = {} as any
    const percentFiltered = {} as any

    extended.map((item: any) => {
        const totalExpired = expired.find((el: any) => el.month === item.month)?.sum || 0
        const totalFiltered = filtered.find((el: any) => el.month === item.month)?.sum || 0
        if (item.sum > 0 && totalExpired > 0) {
            percentExpired[item.month] = ((item.sum / totalExpired) * 100).toFixed(2)
        }
        if (item.sum > 0 && totalFiltered > 0) {
            percentFiltered[item.month] = ((item.sum / totalFiltered) * 100).toFixed(2)
        }
        return true
    })

    const labels = Object.keys(percentExpired)

    const datasets = [
        {
            type: 'line',
            label: 'Gia hạn/KH có dữ liệu',
            data: Object.values(percentFiltered),
            borderWidth: 2,
            datalabels: {
                color: colorPalette[4],
            },
            hidden: true,
        },
        {
            type: 'bar',
            label: 'Gia hạn/KH hết hạn',
            data: Object.values(percentExpired),
            borderWidth: 2,
            datalabels: {
                color: colorPalette[6],
            },
        },
    ] as any

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
            },
            title: {
                display: true,
                text: 'Tỷ lệ gia hạn/ KH hết hạn/ KH có dữ liệu',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                display: true,
                font: {
                    size: 14,
                    weight: 'bold',
                },
                anchor: 'end',
                align: 'top',
                formatter: (value: any) => `${value}%`.replace('.00', ''),
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    } as any

    return (
        <Line data={{ labels, datasets }} options={options} />
    )
}
